import React, { useEffect, useState } from "react";
import "./ProfileInfo.css";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import ProfileHeader from "../ProfileHeader/ProfileHeader";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Link, useNavigate } from "react-router-dom";
import { getuserinfo, updateUserinfo } from "../../../Redux/user/userActions";
import { getAvailibilities } from "../../../Redux/Availibilities/AvailibilitiesActions";
import { getAllHearingAbtTvc } from "../../../Redux/Hearing_about_tvc/HearingAboutTvcActions";
import { getReasons } from "../../../Redux/Reasons/ReasonsActions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loading from "../../../components/Loading/Loading";
import { SlArrowUp, SlArrowDown } from "react-icons/sl";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { BsPatchQuestion } from "react-icons/bs";
//date picker
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import axios from "axios";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

function Profile_info() {
  const animatedComponents = makeAnimated();
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [userType, setUserType] = useState("");
  const navigate = useNavigate();
  const { userinfo, user, loading } = useSelector((store) => store.userReducer);
  const { availibilities } = useSelector(
    (store) => store.AvailibilitiesReducer
  );
  const { hearingAboutTvc } = useSelector(
    (store) => store.HearingAboutTvcReducer
  );
  const { reasons } = useSelector((store) => store.ReasonsReducer);
  const dispatch = useDispatch();
  const [profileData, setProfileData] = useState({});
  const [userData, setUserData] = useState({});
  var step = localStorage.getItem("step");
  var next = +step + 1;
  const role = localStorage.getItem("role");
  const [more, setMore] = useState(false);
  const [expertises, setExpertises] = useState({});
  const [skills, setSkills] = useState({});
  const [skillsToLearn, setSkillsToLearn] = useState({});
  const [dontAskMeSkills, setDontAskMeSkills] = useState({});
  const [allSkills, setAllSkills] = useState();
  const [sectors, setSectors] = useState({});
  const [editProfile, setEditProfile] = useState(false);
  const [hideOrganizationName, setHideOrganizationName] = useState(false);
  const [birthDate, setBirthDate] = useState(null);
  const [allNationalities, setAllNationalities] = useState({});
  const [nationality, setNationality] = useState(null);
  const [allCountries, setAllCountries] = useState({});
  const [country, setCountry] = useState(null);
  const [districts, setDistricts] = useState();
  const [selectedDis, setSelectedDis] = useState();
  const [selectedGov, setSelectedGov] = useState();
  const [counter, setCounter] = useState(0);
  const [governate, setGovernate] = useState();

  const [country1, setCountry1] = useState(null);
  const [districts1, setDistricts1] = useState();
  const [selectedDis1, setSelectedDis1] = useState();
  const [selectedGov1, setSelectedGov1] = useState();
  const [counter1, setCounter1] = useState(0);
  const [governate1, setGovernate1] = useState();

  const [gender, setGender] = useState("");
  const [region, setRegion] = useState("");
  const [otherValue, setOtherValue] = useState("");
  const [occupation, setOccupation] = useState(null);
  const [allExp, setAllExp] = useState([]);
  const [allSectors, setAllSectors] = useState([]);
  const [allOrg, setAllOrg] = useState([]);
  const [newInst, setNewInst] = useState("");
  const [organization, setOrganization] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [email, setEmail] = useState("");
  const [occupationVal, setOccupationVal] = useState("");
  const [availability, setAvailability] = useState([]);
  const [text, setText] = useState(false);
  const [frequency, setFrequency] = useState();
  const [reason, setReason] = useState([]);
  const [allAv, setAllAv] = useState([]);
  const [allReasons, setAllReasons] = useState([]);
  const [isVol, setIsVol] = useState(null);
  const [objective, setobjective] = useState("");
  const [comments, setcomments] = useState("");
  const [anydate, setAnydate] = useState("");
  const [anyenddate, setanyenddate] = useState(false);
  const [openfirstcal, setopenfirstcal] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [defaultDate, setDefaultDate] = useState(null);
  const [fName, setFName] = useState();
  const [lName, setLName] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const token = localStorage.getItem("token");
  const size = useWindowSize();
  const [allHearings, setAllHearings] = useState([]);
  const [hearing, setHearing] = useState(null);
  const [volunteerAsMentor, setVolunteerAsMentor] = useState(false);
  const [contactPersonName, setContactPersoneName] = useState(null);

  const [allDisabilities, setAllDisabilities] = useState();
  const [disabilities, setDisabilities] = useState({});

  const getDisabilities = async () => {
    const response = await axios.get(
      process.env.REACT_APP_API + "/disabilities"
    );

    const newDisabilities = response.data.data.map((d, i) => {
      return { value: d.id, label: lang == "en" ? d.text : d.text_ar };
    });
    setAllDisabilities(newDisabilities);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      border: `1px solid ${state.data.color}`,
    }),
    multiValue: (provided, state) => ({
      ...provided,
      border: `1px solid ${state.data.color}`,
    }),
  };

  // const customStyles = {
  //   multiValue: (provided , state) => ({
  //     ...provided,
  //     // Apply the custom class to the selected options
  //     border: `2px solid ${state.data.color}`
  //   })
  // };

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  const allOcupations = [
    {
      value: "Unoccupied",
      label: lang == "en" ? "Unoccupied" : "شاغر",
    },
    {
      value: "School Student",
      label: lang == "en" ? "School Student" : "طالب مدرسة",
    },

    {
      value: "University Student",
      label: lang == "en" ? "University Student" : "طالب جامعي",
    },
    {
      value: "Employee",
      label: lang == "en" ? "Employee" : "موظف",
    },
    {
      value: "Self Employed",
      label: lang == "en" ? "Self Employed" : "موطف لحسابي الخاص",
    },
  ];

  const allDates = [
    {
      label: lang == "en" ? "Any start date" : "أي تاريخ بدء",
      value: "Any Date",
      id: 1,
    },

    {
      label:
        lang == "en"
          ? "I'd like to select my preferred dates"
          : "أود تحديد التواريخ المفضلة لدي",
      value: "I'd like to select my preferred dates",
      id: 2,
    },
  ];

  const allFrequencies = [
    {
      value: "Any",
      label: lang == "en" ? "Any" : "أيما",
    },
    {
      value: "Daily",
      label: lang == "en" ? "Daily" : "يومي",
    },
    {
      value: "Weekly",
      label: lang == "en" ? "Weekly" : "اسبوعي",
    },
    {
      value: "Monthly",
      label: lang == "en" ? "Monthly" : "شهري",
    },
    {
      value: "Bi-Weekly",
      label: lang == "en" ? "Bi-Weekly" : "نصف أسبوعي",
    },
    {
      value: "Bi-Monthly",
      label: lang == "en" ? "Bi-Monthly" : "نصف شهري",
    },
    {
      value: "One Time Only",
      label: lang == "en" ? "One Time Only" : "مرة واحدة فقط",
    },
  ];
  const maxDate = {
    day: new Date().getDate(),
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear() - 13,
  };

  const handleCheckdates = (e, radio) => {
    setAnydate(e.target.value);
    setanyenddate(true);
  };

  useEffect(() => {
    console.log("disabilities ", disabilities);
  }, [disabilities]);

  useEffect(() => {
    dispatch(getuserinfo());
    dispatch(getAvailibilities());
    dispatch(getAllHearingAbtTvc());
    dispatch(getReasons());
    getDisabilities();
    asyncfunction();
    getExp();
    getSkills();
    getAllSectors();
    setEditProfile(false);
    const date = new Date();
    const defaultDat = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
    setDefaultDate(defaultDat);
  }, []);

  const getInst = async () => {
    const response = await axios.get(
      process.env.REACT_APP_API + "/institutions?type=" + occupationVal
    );

    const newOrg = response.data.data.map((d, i) => {
      return { value: d.name, label: lang == "en" ? d.name : d.name_ar };
    });
    newOrg.push({ value: "Others", label: lang == "en" ? "Others" : "اخرون" });
    setAllOrg(newOrg);
  };

  const getAllSectors = async () => {
    const response = await axios.get(process.env.REACT_APP_API + "/sectors");
    const newSkills = response.data.data.map((d, i) => {
      return { value: d.id, label: lang == "en" ? d.name : d.name_ar };
    });
    setAllSectors(newSkills);
  };
  const getSkills = async () => {
    const response = await axios.get(process.env.REACT_APP_API + "/skills");
    const newS = [];
    response.data.data.map((d, i) => {
      if (d.type == "Soft skills" || d.type == "technical skills") newS.push(d);
    });
    const newSkills = newS.map((d, i) => {
      return {
        value: d.id,
        label: lang == "en" ? d.name : d.name_ar,
        color: d.type == "Soft skills" ? "blue" : "red",
      };
    });

    setAllSkills(newSkills);
  };

  const getExp = async () => {
    const response = await axios.get(process.env.REACT_APP_API + "/skills");
    const newE = [];
    response.data.data.map((d, i) => {
      if (d.type == "E") newE.push(d);
    });
    const newExp = newE.map((d, i) => {
      return { value: d.id, label: lang == "en" ? d.name : d.name_ar };
    });

    setAllExp(newExp);
  };

  const getGov = async (id) => {
    const response = await axios.get(
      process.env.REACT_APP_API + "/governates/getGovernateByCountry/" + id
    );

    const newGov = response.data.data.map((d, i) => {
      return {
        value: d.id,
        label: lang == "en" ? d.name : d.name_ar,
      };
    });

    setGovernate(newGov.filter((s) => s));
    if (response.data?.regions) {
      setRegion(response.data?.regions);
    } else {
      setRegion("");
    }
  };

  const getGov1 = async (id) => {
    const response = await axios.get(
      process.env.REACT_APP_API + "/governates/getGovernateByCountry/" + id
    );

    const newGov = response.data.data.map((d, i) => {
      return {
        value: d.id,
        label: lang == "en" ? d.name : d.name_ar,
      };
    });

    setGovernate1(newGov.filter((s) => s));
  };

  const getDis = async (id) => {
    const response = await axios.get(
      process.env.REACT_APP_API + "/districts/getDistrictByGovernate/" + id
    );

    const newDis = response.data.data.map((d, i) => {
      return {
        value: d.id,
        label: lang == "en" ? d.name : d.name_ar,
      };
    });
    setDistricts(newDis.filter((s) => s));
  };

  const getDis1 = async (id) => {
    const response = await axios.get(
      process.env.REACT_APP_API + "/districts/getDistrictByGovernate/" + id
    );

    const newDis = response.data.data.map((d, i) => {
      return {
        value: d.id,
        label: lang == "en" ? d.name : d.name_ar,
      };
    });
    setDistricts1(newDis.filter((s) => s));
  };

  useEffect(() => {
    setCounter(counter + 1);
    if (country?.value != undefined) getGov(country?.value);

    if (counter > 1) {
      setSelectedGov("");
      setSelectedDis("");
    }
  }, [country]);

  useEffect(() => {
    setCounter1(counter1 + 1);
    if (country1?.value != undefined) getGov1(country1?.value);

    if (counter1 > 1) {
      setSelectedGov1("");
      setSelectedDis1("");
    }
  }, [country1]);

  useEffect(() => {
    if (hearingAboutTvc) {
      const newHearings = hearingAboutTvc.map((d, i) => {
        return {
          value: d.name,
          label: lang == "en" ? d.name : d.name_ar,
          id: d.id,
        };
      });

      setAllHearings(newHearings);
    }
  }, [hearingAboutTvc]);

  useEffect(() => {
    if (selectedGov?.value !== undefined) {
      getDis(selectedGov?.value);
    }
    if (counter == 1 && userinfo?.governate_id != selectedGov?.value) {
      setSelectedDis("");
    }

    if (counter > 1) {
      setSelectedDis("");
    }
  }, [selectedGov]);

  useEffect(() => {
    if (selectedGov1?.value !== undefined) {
      getDis1(selectedGov1?.value);
    }
    if (counter1 == 1 && userinfo?.governate_id1 != selectedGov1?.value) {
      setSelectedDis1("");
    }
    if (counter1 > 1) {
      setSelectedDis1("");
    }
  }, [selectedGov1]);

  useEffect(() => {
    if (availibilities) {
      setAllAv(availibilities);
    }
  }, [availibilities]);

  useEffect(() => {
    if (reasons) {
      setAllReasons(reasons);
    }
  }, [reasons]);

  useEffect(() => {
    if (reason) {
      console.log(reason);
    }
  }, [reason]);

  const allVol = [
    {
      label: lang == "en" ? "Yes" : "نعم",
      value: 1,
      id: 1,
    },

    {
      label: lang == "en" ? "No" : "لا",
      value: 0,
      id: 2,
    },
  ];

  const handleCheckReason = (e, first) => {
    if (first) {
      setIsVol(e.target.value);
    } else {
      if (e.target.checked) {
        const value = parseInt(e.target.value, 10);
        setReason((prevAvailability) => [...prevAvailability, value]);
      } else {
        const value = parseInt(e.target.value, 10);
        // Remove the value from the availability array
        setReason((prevAvailability) =>
          prevAvailability.filter((item) => item !== value)
        );
      }
    }
  };

  const handleCheck = (e, isRadio) => {
    const value = parseInt(e.target.id, 10);
    setOtherValue("");
    if (isRadio) {
      setText(true);
      setAvailability([value]); // Set availability as an array with 'Other'
    } else {
      // Handle other checkboxes
      if (e.target.checked) {
        // Append the value to the availability array
        setAvailability((prevAvailability) =>
          prevAvailability.filter((value) => value !== 7)
        );
        setText(false);
        setAvailability((prevAvailability) => [...prevAvailability, value]);
      } else {
        // Remove the value from the availability array
        setAvailability((prevAvailability) =>
          prevAvailability.filter((item) => item !== value)
        );
      }
    }
  };

  const renderCustomInput2 = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      className="complete_profile_input complete_date_input input_field_editable"
      placeholder={
        new Date().getDate() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getFullYear()
      }
      value={endDate && endDate.day + "-" + endDate.month + "-" + endDate.year}
      onChange={setEndDate}
    />
  );

  const handleOccupationChang = (occupation_val) => {
    if (occupation_val == "School Student") {
      setOccupationVal("School");
      setHideOrganizationName(false);
    } else if (occupation_val == "University Student") {
      setOccupationVal("University");
      setHideOrganizationName(false);
    } else if (
      occupation_val == "Employee" ||
      occupation_val == "Self Employed"
    ) {
      setOccupationVal("Organization");
      setHideOrganizationName(false);
    } else if (occupation_val == "Unoccupied") {
      setHideOrganizationName(true);
    }
  };

  useEffect(() => {
    getInst();
  }, [occupationVal]);

  useEffect(() => {
    if (!availability.includes(7)) {
      setText(false);
      setOtherValue("");
    } else {
      setText(true);
      setOtherValue(userinfo?.other_availability);
    }
  }, [availability]);

  useEffect(() => {
    if (userinfo != undefined) {
      let expertise_array = [];
      let skills_array = [];
      let disabilities_array = [];
      let neededSkills_array = [];
      let notNeededSkills_array = [];
      let sectors_array = [];

      setFName(userinfo?.first_name);
      setLName(userinfo?.last_name);

      if (allHearings && allHearings.length > 0) {
        for (var i = 0; i < allHearings.length; i++) {
          if (allHearings[i].id == userinfo?.hearing_abt_tvc_id) {
            setHearing(allHearings[i]);
          }
        }
      }

      setVolunteerAsMentor(userinfo?.volunteer_as_a_mentor);
      handleInputChange(userinfo?.linked_in);
      setOrganizationName(userinfo?.organization_name);
      setContactPersoneName(userinfo?.contactPersonName);
      setEmail(userinfo?.email_address);
      if (userinfo.any_date) {
        if (document.getElementById("dates_avail_radio1"))
          document.getElementById("dates_avail_radio1").checked = true;
        if (userinfo.date_to) {
          var end = new Date(userinfo.date_to);
          const newEnd = {
            day: end.getDate(),
            month: end.getMonth() + 1,
            year: end.getFullYear(),
          };
          setEndDate(newEnd);
          setanyenddate(true);
          setopenfirstcal(true);
        } else {
          setopenfirstcal(false);
        }
        setAnydate(document.getElementById("dates_avail_radio1").value);
      } else if (userinfo.date_from && userinfo.date_to) {
        if (document.getElementById("dates_avail_radio2")) {
          document.getElementById("dates_avail_radio2").checked = true;
          setAnydate(document.getElementById("dates_avail_radio2").value);
        }
        var start = new Date(userinfo.date_from);
        var end = new Date(userinfo.date_to);

        const newstart = {
          day: start.getDate(),
          month: start.getMonth() + 1,
          year: start.getFullYear(),
        };

        const newEnd = {
          day: end.getDate(),
          month: end.getMonth() + 1,
          year: end.getFullYear(),
        };
        setEndDate(newEnd);
        setStartDate(newstart);
      }

      if (userinfo?.date_of_birth) {
        const date = new Date(userinfo?.date_of_birth);
        const newdate = {
          day: date.getDate(),
          month: date.getMonth() + 1,
          year: date.getFullYear(),
        };
        setBirthDate(newdate);
      }

      if (userinfo?.objective) {
        setobjective(userinfo.objective);
      }

      if (userinfo?.comments) {
        setcomments(userinfo.comments);
      }

      if (userinfo?.volunteered_before) {
        setIsVol(allVol[0].value);
      } else {
        setIsVol(allVol[1].value);
      }

      if (userinfo?.volunteer_availability) {
        userinfo?.volunteer_availability?.map((availability) => {
          setAvailability((prevAvailability) => [
            ...prevAvailability,
            availability.availability_id,
          ]);
        });
      }

      if (userinfo?.volunteer_reasons) {
        userinfo?.volunteer_reasons?.map((reason) => {
          setReason((prevAvailability) => [
            ...prevAvailability,
            reason.reson_id,
          ]);
        });
      }

      if (userinfo.frequency) {
        const newFreq = allFrequencies.filter(
          (s) => s.value == userinfo.frequency
        );
        setFrequency(newFreq[0]);
      }

      if (userinfo?.occupation) {
        handleOccupationChang(userinfo?.occupation);
        for (var i = 0; i < allOcupations.length; i++) {
          if (allOcupations[i].value == userinfo.occupation) {
            setOccupation(allOcupations[i]);
          }
        }
      }

      if (userinfo && allOrg) {
        if (userinfo?.institution) {
          if (allOrg.find((s) => s.value == userinfo?.institution)) {
            var newO = allOrg.filter((s) => s.value == userinfo?.institution);
            setOrganization(newO);
            setNewInst("");
          } else {
            setOrganization({
              value: "Others",
              label: "Others",
            });
            const inst = userinfo?.institution.split("Others: ").pop();
            setNewInst(inst);
          }
        }
      }

      if (allNationalities && allNationalities.length > 0) {
        for (var i = 0; i < allNationalities.length; i++) {
          if (allNationalities[i].value == userinfo?.nationality_id) {
            setNationality(allNationalities[i]);
          }
        }
      }

      if (userinfo?.country_out_lebanon) {
        var newC = {
          value: userinfo?.country_out_lebanon,
          label:
            lang == "en"
              ? userinfo?.country_name_out_lebanon
              : userinfo?.country_name_ar_out_lebanon,
        };

        setCountry(newC);
        setCounter(1);
      } else {
        if (userinfo?.district_id && userinfo?.governate_id) {
          var newDis = {
            value: userinfo?.district_id,
            label:
              lang == "en"
                ? userinfo?.district_name
                : userinfo?.district_name_ar,
          };

          setSelectedDis(newDis);

          var newC = {
            value: 1,
            label: lang == "en" ? "Lebanon" : "لبنان",
          };

          setCountry(newC);

          var newGov = {
            value: userinfo?.governate_id,
            label:
              lang == "en"
                ? userinfo?.governate_name
                : userinfo?.governate_name_ar,
          };

          setSelectedGov(newGov);
          setCounter(1);
        }
      }

      if (userinfo?.second_country?.id) {
        var newC = {
          value: userinfo?.second_country?.id,
          label:
            lang == "en"
              ? userinfo?.second_country?.name
              : userinfo?.second_country?.name_ar,
        };

        setCountry1(newC);
        setCounter1(1);
      } else {
        if (userinfo?.district_id1 && userinfo?.governate_id1) {
          var newDis = {
            value: userinfo?.district_id1,
            label:
              lang == "en"
                ? userinfo?.district_name1
                : userinfo?.district_name_ar1,
          };

          setSelectedDis1(newDis);

          var newC = {
            value: 1,
            label: lang == "en" ? "Lebanon" : "لبنان",
          };

          setCountry1(newC);

          var newGov = {
            value: userinfo?.governate_id1,
            label:
              lang == "en"
                ? userinfo?.governate_name1
                : userinfo?.governate_name_ar1,
          };

          setSelectedGov1(newGov);
          setCounter1(1);
        }
      }

      userinfo?.expertises?.map((expertise) => {
        expertise_array.push(
          lang === "en"
            ? {
                value: expertise?.expertise_id,
                label: expertise?.["skill.name"],
              }
            : {
                value: expertise?.expertise_id,
                label: expertise?.["skill.name_ar"],
              }
        );
      });

      userinfo?.skills?.map((skill) => {
        skills_array.push(
          lang === "en"
            ? {
                value: skill?.skill_id,
                label: skill?.["skill.name"],
                color: skill?.["skill.type"] == "Soft skills" ? "blue" : "red",
              }
            : {
                value: skill?.skill_id,
                label: skill?.["skill.name_ar"],
                color: skill?.["skill.type"] == "Soft skills" ? "blue" : "red",
              }
        );
      });

      userinfo?.disabilities?.map((disability) => {
        disabilities_array.push(
          lang === "en"
            ? {
                value: disability?.disability_id,
                label: disability?.disability?.text,
              }
            : {
                value: disability?.disability_id,
                label: disability?.disability?.text_ar,
              }
        );
      });

      userinfo?.skillsNeeded?.map((skill) => {
        neededSkills_array.push(
          lang === "en"
            ? {
                value: skill?.skill_id,
                label: skill?.["skill.name"],
                color: skill?.["skill.type"] == "Soft skills" ? "blue" : "red",
              }
            : {
                value: skill?.skill_id,
                label: skill?.["skill.name_ar"],
                color: skill?.["skill.type"] == "Soft skills" ? "blue" : "red",
              }
        );
      });

      userinfo?.skillsNotNeeded?.map((skill) => {
        notNeededSkills_array.push(
          lang === "en"
            ? {
                value: skill?.skill_id,
                label: skill?.["skill.name"],
                color: skill?.["skill.type"] == "Soft skills" ? "blue" : "red",
              }
            : {
                value: skill?.skill_id,
                label: skill?.["skill.name_ar"],
                color: skill?.["skill.type"] == "Soft skills" ? "blue" : "red",
              }
        );
      });

      userinfo?.sectors?.map((sector) => {
        sectors_array.push(
          lang === "en"
            ? {
                value: sector?.sector_id,
                label: sector?.["sector.name"],
              }
            : {
                value: sector?.sector_id,
                label: sector?.["sector.name_ar"],
              }
        );
      });

      setExpertises(expertise_array);
      setSkills(skills_array);
      setDisabilities(disabilities_array);

      setSkillsToLearn(neededSkills_array);
      setDontAskMeSkills(notNeededSkills_array);

      setSectors(sectors_array);
      setProfileData(userinfo);
      setUserData(user);
      setGender(userinfo?.gender);
      setUserType(user.role == 4 ? "ngo" : "volounteer");
    }
  }, [userinfo, allCountries, allNationalities]);

  useEffect(() => {
    setLang(i18n.language);
    asyncfunction();
  }, [i18n.language]);

  const asyncfunction = async () => {
    const resp_countries = await axios.get(
      process.env.REACT_APP_API + "/countries"
    );

    const countries = resp_countries.data.data.map((d, i) => {
      return { value: d.id, label: lang == "en" ? d.name : d.name_ar };
    });

    setAllCountries(countries);

    const resp_nat = await axios.get(
      process.env.REACT_APP_API + "/nationalities"
    );

    const nationalities = resp_nat.data.data.map((d, i) => {
      return { value: d.id, label: lang == "en" ? d.name : d.name_ar };
    });

    setAllNationalities(nationalities);
  };

  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      className="complete_profile_input complete_date_input input_field_editable"
      placeholder="Date of Birth"
      value={
        birthDate
          ? birthDate.day + "-" + birthDate.month + "-" + birthDate.year
          : ""
      }
    />
  );

  const renderCustomInput1 = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      className="complete_profile_input complete_date_input input_field_editable"
      placeholder={
        new Date().getDate() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getFullYear()
      }
      value={
        startDate &&
        startDate.day + "-" + startDate.month + "-" + startDate.year
      }
      onChange={setStartDate}
    />
  );

  const [isValid, setIsValid] = useState(true);

  const handleInputChange = (url) => {
    setLinkedIn(url);
    validateLinkedInURL(url);
  };

  const validateLinkedInURL = (url) => {
    // Regular expression pattern to match LinkedIn URLs
    const regex =
      /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/;
    setIsValid(regex.test(url));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (userType === "ngo") {
      const user = {
        name: organizationName,
        email_address: email,
        hearing_abt_tvc_id: hearing?.id,
        contactPersonName: contactPersonName,
      };
      dispatch(updateUserinfo(token, user, navigate, "profile-info"));
    } else {
      const date_of_birth = new Date();
      if (birthDate != null) {
        date_of_birth.setDate(birthDate.day);
        date_of_birth.setMonth(birthDate.month - 1);
        date_of_birth.setFullYear(birthDate.year);
      }
      if (fName && lName && nationality && date_of_birth && gender) {
        const newExpet = expertises.map((d, i) => {
          return d.value.toString();
        });
        await axios.post(
          process.env.REACT_APP_API + "/volunteer_expertises",
          {
            expertise_id: newExpet,
          },
          { Authorization: "Bearer " + localStorage.getItem("token") }
        );

        const newSkills = skills.map((d, i) => {
          return d.value.toString();
        });

        await axios.post(
          process.env.REACT_APP_API + "/volunteer_skills",
          {
            skill_id: newSkills,
          },
          { Authorization: "Bearer " + localStorage.getItem("token") }
        );

        const newDisabilities = disabilities.map((d, i) => {
          return d.value.toString();
        });

        await axios.post(
          process.env.REACT_APP_API + "/volunteer_disabilities",
          {
            disability_id: newDisabilities,
          },
          { Authorization: "Bearer " + localStorage.getItem("token") }
        );

        const newSkillsToLearn = skillsToLearn.map((d, i) => {
          return d.value.toString();
        });

        await axios.post(
          process.env.REACT_APP_API + "/volunteer_needed_skills",
          {
            skill_id: newSkillsToLearn,
          },
          { Authorization: "Bearer " + localStorage.getItem("token") }
        );

        const newDontAskMeSkills = dontAskMeSkills.map((d, i) => {
          return d.value.toString();
        });

        await axios.post(
          process.env.REACT_APP_API + "/volunteer_notNeeded_skills",
          {
            skill_id: newDontAskMeSkills,
          },
          { Authorization: "Bearer " + localStorage.getItem("token") }
        );

        const newSect = sectors.map((d, i) => {
          return d.value.toString();
        });
        let userDates = {};
        if (anydate == "Any Date") {
          const ending_date = new Date();
          if (endDate) {
            ending_date.setDate(endDate.day);
            ending_date.setMonth(endDate.month - 1);
            ending_date.setFullYear(endDate.year);
          }
          userDates = {
            any_date: true,
            date_from: null,
            date_to: openfirstcal ? ending_date : null,
          };
        } else if (anydate == "I'd like to select my preferred dates") {
          if (endDate && startDate) {
            setError(null);

            const starting_date = new Date();
            starting_date.setDate(startDate.day);
            starting_date.setMonth(startDate.month - 1);
            starting_date.setFullYear(startDate.year);
            const ending_date = new Date();
            ending_date.setDate(endDate.day);
            ending_date.setMonth(endDate.month - 1);
            ending_date.setFullYear(endDate.year);
            if (startDate && endDate) {
              userDates = {
                any_date: false,
                date_from: starting_date,
                date_to: ending_date,
              };
            }
          } else {
            setError("Please fill all required fields");
          }
        }
        let district_id;
        let count_id;
        let district_id1;
        let count_id1;
        if (country?.value == 1) {
          district_id = selectedDis.value;
          count_id = null;
        } else {
          district_id = null;
          count_id = country.value;
        }

        if (country1?.value == 1) {
          district_id1 = selectedDis1.value;
          count_id1 = null;
        } else {
          district_id1 = null;
          count_id1 = country1.value;
        }

        const user = {
          first_name: fName,
          last_name: lName,
          nationality_id: nationality.value,
          district_id: district_id,
          count_id: count_id,
          district_id1: district_id1,
          country_id: count_id1,
          date_of_birth: date_of_birth,
          gender: gender,
          linked_in: linkedIn ? linkedIn : null,
          occupation: occupation.value,
          hearing_abt_tvc_id: hearing?.id,
          volunteer_as_a_mentor: volunteerAsMentor,
          sector_id: newSect,
          institution:
            organization.label != "Others"
              ? organization.label
              : "Others: " + newInst,
          frequency: frequency?.value,
          availability: [...new Set(availability)],
          other_availability: otherValue,
          volunteered_before: isVol,
          reasons: [...new Set(reason)],
          objective: objective,
          comments: comments,
          step: 10,
          ...userDates,
        };

        console.log(availability);
        // console.log('useruser' , user)
        dispatch(updateUserinfo(token, user, navigate, "profile-info"));

        // dispatch(updateUserinfo(token, user, navigate, 2));
      } else setError("Please enter all required fields");
    }
  };

  return (
    <div className="profie_status_main_container">
      {<ProfileHeader />}
      {loading ? (
        <Loading />
      ) : (
        <div
          className={
            lang == "ar"
              ? "profile_info_container_ar"
              : "profile_info_container"
          }
        >
          {userType === "ngo" && (
            <img
              className="opp_card_img"
              src={
                process.env.REACT_APP_API +
                "/Organization/" +
                profileData?.img_url
              }
              alt="opp_card_img"
            />
          )}
          <div style={{ width: userType === "ngo" ? "80%" : "100%" }}>
            <div className="progress_container">
              <CircularProgressbar
                className="progress_circle"
                value={
                  role == 3
                    ? step == -1
                      ? 100
                      : ((step / 10) * 100)?.toFixed(0)
                    : step == -1
                    ? 100
                    : ((step / 5) * 100)?.toFixed(0)
                }
                text={
                  role == 3
                    ? `${step == -1 ? 100 : ((step / 10) * 100)?.toFixed(0)}%`
                    : `${step == -1 ? 100 : ((step / 5) * 100)?.toFixed(0)}%`
                }
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0.25,
                  pathColor: `var(--primary)`,
                  strokeColor: "#72c095",
                  trailColor: "#e6e6e6",
                  backgroundColor: "#3e98c7",
                })}
                strokeWidth={10}
                style={{ fontWeight: "bold" }}
              />
              <div className="margin-20">
                {t("myProfile.profile_completed")}
                <div style={{ marginTop: "10px" }}>
                  {(role == 3 ? step != 10 : step != 5) ? (
                    <button
                      type="button"
                      className="button_profile"
                      onClick={() => {
                        setEditProfile(true);
                      }}
                      disabled={editProfile ? true : false}
                    >
                      {t("myProfile.complete_your_profile")}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="button_profile"
                      onClick={() => {
                        setEditProfile(true);
                      }}
                      disabled={editProfile ? true : false}
                    >
                      {t("myProfile.complete_your_profile2")}
                    </button>
                  )}
                </div>
              </div>
            </div>
            {userType !== "ngo" ? (
              <Link
                to={"/profile-status"}
                style={{ color: "blue", marginLeft: "2%" }}
              >
                {t("myProfile.update your status")}
              </Link>
            ) : null}

            <div className="form_container" style={{ marginTop: "2%" }}>
              <form onSubmit={(e) => onSubmit(e)}>
                <div className="input_row">
                  <div className="row-fiels">
                    <label className="profile_info_labels">
                      {userType === "ngo"
                        ? t("ContactUs.org_name")
                        : t("CompleteProf.complete_your_profile_2")}
                    </label>
                    <input
                      type="text"
                      name={userType === "ngo" ? "name" : "firstName"}
                      // defaultValue={
                      //   userType === "ngo"
                      //     ? profileData?.organization_name !== null
                      //       ? profileData?.organization_name
                      //       : ""
                      //     : profileData?.first_name !== null
                      //     ? profileData?.first_name
                      //     : ""
                      // }
                      defaultValue={
                        userType === "ngo" ? organizationName : fName
                      }
                      onChange={(e) =>
                        userType === "ngo"
                          ? setOrganizationName(e.target.value)
                          : setFName(e.target.value)
                      }
                      className={
                        editProfile
                          ? "input_field input_field_editable"
                          : "input_field"
                      }
                      placeholder={userType === "ngo" ? "Name" : "First name"}
                      required
                      disabled={editProfile ? false : true}
                    />
                  </div>
                  <div className="row-fiels">
                    <label className="profile_info_labels">
                      {userType === "ngo"
                        ? t("CompleteProf.email")
                        : t("CompleteProf.complete_your_profile_3")}
                    </label>
                    {userType === "ngo" ? (
                      <>
                        {" "}
                        <input
                          type="text"
                          // disabled={userType === "ngo" ? true : false}
                          name={userType === "ngo" ? "email" : "lastName"}
                          placeholder={
                            userType === "ngo" ? "email" : "Last name"
                          }
                          // defaultValue={
                          //   userType === "ngo"
                          //     ? userData?.email !== null
                          //       ? userData?.email
                          //       : ""
                          //     : profileData?.last_name !== null
                          //     ? profileData?.last_name
                          //     : ""
                          // }
                          defaultValue={userType === "ngo" ? email : lName}
                          onChange={(e) =>
                            userType === "ngo"
                              ? setEmail(e.target.value)
                              : setLName(e.target.value)
                          }
                          className={
                            editProfile ? "input_field " : "input_field"
                          }
                          required
                          disabled={true}
                        />
                      </>
                    ) : (
                      <input
                        type="text"
                        // disabled={userType === "ngo" ? true : false}
                        name={userType === "ngo" ? "email" : "lastName"}
                        placeholder={userType === "ngo" ? "email" : "Last name"}
                        // defaultValue={
                        //   userType === "ngo"
                        //     ? userData?.email !== null
                        //       ? userData?.email
                        //       : ""
                        //     : profileData?.last_name !== null
                        //     ? profileData?.last_name
                        //     : ""
                        // }
                        defaultValue={userType === "ngo" ? email : lName}
                        onChange={(e) =>
                          userType === "ngo"
                            ? setEmail(e.target.value)
                            : setLName(e.target.value)
                        }
                        className={
                          editProfile
                            ? "input_field input_field_editable"
                            : "input_field"
                        }
                        required
                        disabled={editProfile ? false : true}
                      />
                    )}
                  </div>
                </div>

                {userType === "ngo" && (
                  <div className="input_row">
                    <div className="row-fiels">
                      <label className="profile_info_labels">
                        {t("CompleteProf.hearing_abt_tvc")}
                      </label>
                      <Select
                        value={hearing && hearing}
                        className={
                          editProfile
                            ? " edit_profile_select select_field_editable"
                            : "edit_profile_select select_field_editable_not_editable"
                        }
                        name="hearings"
                        // onChange={setOccupation}
                        placeholder={t("CompleteProf.select")}
                        required
                        isSearchable={true}
                        options={allHearings}
                        disabled={editProfile ? false : true}
                        onChange={(e) => {
                          setHearing(e);
                        }}
                        components={
                          !editProfile && {
                            Menu: () => null,
                            MenuList: () => null,
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }
                        }
                      />
                    </div>
                    <div className="row-fiels"></div>
                  </div>
                )}

                {userType === "ngo" && (
                  <div className="input_row">
                    <div className="row-fiels">
                      <label className="profile_info_labels">
                        {/* {t("CompleteProf.hearing_abt_tvc")} */}
                        Contact Person Name
                      </label>
                      <input
                        type="text"
                        value={contactPersonName || ""}
                        name="contactPersonName"
                        className={
                          editProfile
                            ? "input_field input_field_editable"
                            : "input_field"
                        }
                        required
                        disabled={editProfile ? false : true}
                        onChange={(e) => setContactPersoneName(e.target.value)}
                      />
                    </div>
                    <div className="row-fiels"></div>
                  </div>
                )}

                {userType !== "ngo" && (
                  <>
                    <div className="input_row">
                      <div className="row-fiels">
                        <label className="profile_info_labels">
                          {t("CompleteProf.complete_your_profile_6")}
                        </label>
                        {!editProfile && (
                          <div class="input-icons">
                            <i
                              className={
                                lang == "ar"
                                  ? "calendar-icon left-50"
                                  : "calendar-icon right-50"
                              }
                            ></i>

                            <input
                              value={
                                profileData?.date_of_birth !== null
                                  ? moment(profileData?.date_of_birth).format(
                                      "DD-MM-YYYY"
                                    )
                                  : "dd-mm-yyyy"
                              }
                              name="dob"
                              placeholder="dd-mm-yyyy"
                              type="text"
                              required
                              disabled={true}
                            />
                          </div>
                        )}
                        {editProfile && (
                          <div class="input-icons">
                            <DatePicker
                              colorPrimary="var(--primary)"
                              value={birthDate ? birthDate : maxDate}
                              onChange={setBirthDate}
                              renderInput={renderCustomInput}
                              shouldHighlightWeekends
                              calendarClassName="responsive-calendar datepicker_cont "
                              format="yyyy-mm-dd"
                              maximumDate={maxDate}
                              required
                              className="input_field_editable"
                            />
                          </div>
                        )}
                      </div>
                      <div className="row-fiels">
                        <label className="profile_info_labels">
                          {t("CompleteProf.complete_your_profile_4")}
                        </label>
                        <Select
                          value={nationality && nationality}
                          className={
                            editProfile
                              ? " edit_profile_select select_field_editable"
                              : "edit_profile_select select_field_editable_not_editable"
                          }
                          name="nationality"
                          onChange={setNationality}
                          placeholder={t(
                            "CompleteProf.complete_your_profile_4"
                          )}
                          options={allNationalities}
                          isSearchable={true}
                          required
                          components={
                            !editProfile && {
                              Menu: () => null,
                              MenuList: () => null,
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }
                          }
                        />
                      </div>
                    </div>
                    <div className="input_row">
                      <div className="row-fiels">
                        <label className="profile_info_labels">
                          {t("CompleteProf.complete_your_profile_5")}
                        </label>

                        <Select
                          value={country && country}
                          className={
                            editProfile
                              ? " edit_profile_select select_field_editable"
                              : "edit_profile_select select_field_editable_not_editable"
                          }
                          name="country"
                          onChange={setCountry}
                          placeholder={t(
                            "CompleteProf.complete_your_profile_5"
                          )}
                          required
                          isSearchable={true}
                          options={allCountries}
                          components={
                            !editProfile && {
                              Menu: () => null,
                              MenuList: () => null,
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }
                          }
                        />
                        {country && country?.value == 1 && governate && (
                          <Select
                            value={selectedGov && selectedGov}
                            className={
                              editProfile
                                ? " edit_profile_select select_field_editable"
                                : "edit_profile_select select_field_editable_not_editable"
                            }
                            name="governate"
                            onChange={setSelectedGov}
                            placeholder={t("opportunity.create.governate")}
                            options={governate}
                            required={country?.value == 1 ? true : false}
                            components={
                              !editProfile && {
                                Menu: () => null,
                                MenuList: () => null,
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }
                            }
                          />
                        )}

                        {country?.value == 1 && districts && (
                          <Select
                            value={selectedDis && selectedDis}
                            className={
                              editProfile
                                ? " edit_profile_select select_field_editable"
                                : "edit_profile_select select_field_editable_not_editable"
                            }
                            name="districts"
                            onChange={setSelectedDis}
                            placeholder={t("opportunity.create.district")}
                            options={districts}
                            required={country?.value == 1 ? true : false}
                            components={
                              !editProfile && {
                                Menu: () => null,
                                MenuList: () => null,
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }
                            }
                          />
                        )}
                      </div>

                      <div className="row-fiels">
                        <label className="profile_info_labels">
                          {t("CompleteProf.location2")}
                        </label>

                        <Select
                          value={country1 && country1}
                          className={
                            editProfile
                              ? " edit_profile_select select_field_editable"
                              : "edit_profile_select select_field_editable_not_editable"
                          }
                          name="country1"
                          onChange={setCountry1}
                          placeholder={t(
                            "CompleteProf.complete_your_profile_5"
                          )}
                          required
                          isSearchable={true}
                          options={allCountries}
                          components={
                            !editProfile && {
                              Menu: () => null,
                              MenuList: () => null,
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }
                          }
                        />
                        {country1 && country1.value == 1 && governate1 && (
                          <Select
                            value={selectedGov1 && selectedGov1}
                            className={
                              editProfile
                                ? " edit_profile_select select_field_editable"
                                : "edit_profile_select select_field_editable_not_editable"
                            }
                            name="governate"
                            onChange={setSelectedGov1}
                            placeholder={t("opportunity.create.governate")}
                            options={governate1}
                            required={country1?.value == 1 ? true : false}
                            components={
                              !editProfile && {
                                Menu: () => null,
                                MenuList: () => null,
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }
                            }
                          />
                        )}

                        {country1?.value == 1 && districts1 && (
                          <Select
                            value={selectedDis1 && selectedDis1}
                            className={
                              editProfile
                                ? " edit_profile_select select_field_editable"
                                : "edit_profile_select select_field_editable_not_editable"
                            }
                            name="districts"
                            onChange={setSelectedDis1}
                            placeholder={t("opportunity.create.district")}
                            options={districts1}
                            required={country1?.value == 1 ? true : false}
                            components={
                              !editProfile && {
                                Menu: () => null,
                                MenuList: () => null,
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }
                            }
                          />
                        )}
                      </div>
                    </div>

                    <div className="input_row">
                      <div className="row-fiels">
                        <label className="profile_info_labels">
                          {t("CompleteProf.region")}
                        </label>

                        <input
                          value={region}
                          className="input_field"
                          name="region"
                          placeholder={t("CompleteProf.region")}
                          type="text"
                          disabled={true}
                        />
                      </div>
                      <div className="row-fiels"></div>
                    </div>
                    <div className="input_row">
                      <div className="row-fiels">
                        <label className="profile_info_labels">
                          {t("CompleteProf.gender")}
                        </label>

                        <div className="complete_step1_body2 padding-top-10">
                          <div className="complete_radio_comp">
                            <input
                              onChange={(e) => setGender(e.target.value)}
                              className={
                                lang == "en"
                                  ? "complete_radio_btn"
                                  : "complete_radio_btn_ar"
                              }
                              type="radio"
                              id="male"
                              name="gender"
                              value="Male"
                              checked={gender == "Male" ? "checked" : ""}
                              disabled={editProfile ? false : true}
                            />
                            <label for="male" style={{ cursor: "pointer" }}>
                              {" "}
                              {t("CompleteProf.complete_your_profile_7")}
                            </label>
                          </div>
                          <div className="complete_radio_comp">
                            <input
                              onChange={(e) => setGender(e.target.value)}
                              className={
                                lang == "en"
                                  ? "complete_radio_btn"
                                  : "complete_radio_btn_ar"
                              }
                              type="radio"
                              id="female"
                              name="gender"
                              value="Female"
                              checked={gender == "Female" ? "checked" : ""}
                              disabled={editProfile ? false : true}
                            />
                            <label for="female" style={{ cursor: "pointer" }}>
                              {t("CompleteProf.complete_your_profile_8")}
                            </label>
                          </div>
                          <div className="complete_radio_comp">
                            <input
                              onChange={(e) => setGender(e.target.value)}
                              className={
                                lang == "en"
                                  ? "complete_radio_btn"
                                  : "complete_radio_btn_ar"
                              }
                              type="radio"
                              id="non_binary"
                              name="gender"
                              value="Non-Binary"
                              checked={gender == "Non-Binary" ? "checked" : ""}
                              disabled={editProfile ? false : true}
                            />
                            <label
                              for="non_binary"
                              style={{ cursor: "pointer" }}
                            >
                              {t("CompleteProf.complete_your_profile_9")}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row-fiels">
                        <label className="profile_info_labels">
                          {t("CompleteProf.complete_your_profile_10")}
                        </label>

                        {isValid ? (
                          <FaCheckCircle
                            style={{
                              color: "#72c095",
                              marginLeft: "5px",
                              fontSize: "20px",
                            }}
                          />
                        ) : (
                          <FaTimesCircle
                            style={{
                              color: "#f06a80",
                              marginLeft: "5px",
                              fontSize: "20px",
                            }}
                          />
                        )}

                        <input
                          // defaultValue={
                          //   profileData?.linked_in !== null
                          //     ? profileData?.linked_in
                          //     : ""
                          // }
                          value={linkedIn}
                          onChange={(e) => handleInputChange(e.target.value)}
                          className={
                            editProfile
                              ? "input_field input_field_editable"
                              : "input_field"
                          }
                          name="linkedIn"
                          placeholder="LinkedIn"
                          type="text"
                          disabled={editProfile ? false : true}
                        />
                      </div>
                    </div>

                    <div className="show_more_less_div">
                      <div className="divider_line"></div>

                      {!more ? (
                        <SlArrowDown
                          size="1.5rem"
                          onClick={() => {
                            setMore(true);
                          }}
                        />
                      ) : (
                        <SlArrowUp
                          size="1.5rem"
                          onClick={() => {
                            setMore(false);
                          }}
                        />
                      )}
                    </div>

                    <div className={more ? "display_more" : "hide_more"}>
                      <div className="input_row">
                        <div className="row-fiels">
                          <label className="profile_info_labels">
                            {t("CompleteProf.complete_your_profile_13")}
                          </label>

                          <Select
                            value={occupation && occupation}
                            className={
                              editProfile
                                ? " edit_profile_select select_field_editable"
                                : "edit_profile_select select_field_editable_not_editable"
                            }
                            name="occupation"
                            // onChange={setOccupation}
                            placeholder={t("CompleteProf.select_occupation")}
                            required
                            isSearchable={true}
                            options={allOcupations}
                            disabled={editProfile ? false : true}
                            onChange={(e) => {
                              setOccupation(e);
                              handleOccupationChang(e.value);
                              setOrganization("");
                            }}
                            components={
                              !editProfile && {
                                Menu: () => null,
                                MenuList: () => null,
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }
                            }
                          />
                        </div>
                        <div className="row-fiels">
                          <label className="profile_info_labels">
                            {t("CompleteProf.your_expertise")}
                          </label>
                          <Select
                            className={
                              editProfile
                                ? "multiselect_editable"
                                : "multiselect_not_editable"
                            }
                            value={expertises}
                            placeholder={t("CompleteProf.select_expertise")}
                            isMulti
                            options={allExp}
                            disabled={editProfile ? false : true}
                            onChange={(e) => setExpertises(e)}
                            components={
                              !editProfile && {
                                Menu: () => null,
                                MenuList: () => null,
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }
                            }
                          />
                        </div>
                      </div>

                      <div className="input_row">
                        <div className="row-fiels">
                          <label className="profile_info_labels">
                            {t("CompleteProf.your_skills")}
                          </label>
                          <Select
                            className={
                              editProfile
                                ? "multiselect_editable"
                                : "multiselect_not_editable"
                            }
                            placeholder={t("CompleteProf.select_skills")}
                            value={skills}
                            isMulti
                            options={allSkills}
                            onChange={(e) => setSkills(e)}
                            disabled={editProfile ? false : true}
                            components={
                              !editProfile && {
                                Menu: () => null,
                                MenuList: () => null,
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }
                            }
                            styles={customStyles}
                          />
                        </div>
                        <div className="row-fiels">
                          <label className="profile_info_labels">
                            {t("CompleteProf.your_preferred_sectors")}
                          </label>
                          <Select
                            className={
                              editProfile
                                ? "multiselect_editable"
                                : "multiselect_not_editable"
                            }
                            value={sectors}
                            isMulti
                            onChange={(e) => setSectors(e)}
                            options={allSectors}
                            disabled={editProfile ? false : true}
                            required
                            placeholder={t(
                              "CompleteProf.select_preffered_sectors"
                            )}
                            components={
                              !editProfile && {
                                Menu: () => null,
                                MenuList: () => null,
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }
                            }
                          />
                        </div>
                      </div>

                      <div className="input_row">
                        <div className="row-fiels">
                          <label className="profile_info_labels">
                            {t("CompleteProf.skills_to_learn")}
                          </label>
                          <Select
                            className={
                              editProfile
                                ? "multiselect_editable"
                                : "multiselect_not_editable"
                            }
                            value={skillsToLearn}
                            isMulti
                            options={allSkills}
                            onChange={(e) => setSkillsToLearn(e)}
                            disabled={editProfile ? false : true}
                            placeholder={t("CompleteProf.select_skills")}
                            components={
                              !editProfile && {
                                Menu: () => null,
                                MenuList: () => null,
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }
                            }
                            styles={customStyles}
                          />
                        </div>

                        <div className="row-fiels">
                          <label className="profile_info_labels">
                            {t("CompleteProf.dont_ask_me")}
                          </label>
                          <Select
                            className={
                              editProfile
                                ? "multiselect_editable"
                                : "multiselect_not_editable"
                            }
                            placeholder={t("CompleteProf.select_skills")}
                            value={dontAskMeSkills}
                            isMulti
                            options={allSkills}
                            onChange={(e) => setDontAskMeSkills(e)}
                            disabled={editProfile ? false : true}
                            components={
                              !editProfile && {
                                Menu: () => null,
                                MenuList: () => null,
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }
                            }
                            styles={customStyles}
                          />
                        </div>
                      </div>

                      <div className="input_row">
                        <div className="row-fiels">
                          <label className="profile_info_labels">
                            {t("CompleteProf.hearing_abt_tvc")}
                          </label>
                          <Select
                            value={hearing && hearing}
                            className={
                              editProfile
                                ? " edit_profile_select select_field_editable"
                                : "edit_profile_select select_field_editable_not_editable"
                            }
                            name="hearings"
                            // onChange={setOccupation}
                            placeholder={t("CompleteProf.select_mean")}
                            required
                            isSearchable={true}
                            options={allHearings}
                            disabled={editProfile ? false : true}
                            onChange={(e) => {
                              setHearing(e);
                            }}
                            components={
                              !editProfile && {
                                Menu: () => null,
                                MenuList: () => null,
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }
                            }
                          />
                        </div>
                        <div className="row-fiels">
                          <div className="row-fiels">
                            <label className="profile_info_labels">
                              {t("CompleteProf.volunteer_as_mentor")}
                            </label>

                            <div className="complete_step1_body2 padding-top-10">
                              <div className="complete_radio_comp">
                                <input
                                  onChange={(e) => setVolunteerAsMentor(true)}
                                  className={
                                    lang == "en"
                                      ? "complete_radio_btn"
                                      : "complete_radio_btn_ar"
                                  }
                                  type="radio"
                                  id="mentor"
                                  name="volunteerAsMentor"
                                  checked={
                                    volunteerAsMentor == true ? "checked" : ""
                                  }
                                  disabled={editProfile ? false : true}
                                />
                                <label for="male" style={{ cursor: "pointer" }}>
                                  {" "}
                                  {t("CompleteProf.yes")}
                                </label>
                              </div>
                              <div className="complete_radio_comp">
                                <input
                                  onChange={(e) => setVolunteerAsMentor(false)}
                                  className={
                                    lang == "en"
                                      ? "complete_radio_btn"
                                      : "complete_radio_btn_ar"
                                  }
                                  type="radio"
                                  id="notMentor"
                                  name="volunteerAsMentor"
                                  checked={
                                    volunteerAsMentor == false ? "checked" : ""
                                  }
                                  disabled={editProfile ? false : true}
                                />
                                <label
                                  for="female"
                                  style={{ cursor: "pointer" }}
                                >
                                  {t("CompleteProf.no")}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="input_row">
                        <div className="row-fiels">
                          <label className="profile_info_labels">
                            {t("CompleteProf.disabilities_question")}
                          </label>
                          <Select
                            className={
                              editProfile
                                ? "multiselect_editable"
                                : "multiselect_not_editable"
                            }
                            value={disabilities}
                            isMulti
                            options={allDisabilities}
                            onChange={(e) => setDisabilities(e)}
                            disabled={editProfile ? false : true}
                            required
                            placeholder={t("CompleteProf.select_what_applies")}
                            components={
                              !editProfile && {
                                Menu: () => null,
                                MenuList: () => null,
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }
                            }
                            styles={customStyles}
                          />
                        </div>
                        <div className="row-fiels">
                          {!hideOrganizationName && (
                            <>
                              <label className="profile_info_labels">
                                {t("CompleteProf.Join_the_Circle_1_Org")}
                              </label>

                              <Select
                                value={organization && organization}
                                className="edit_profile_select"
                                disabled={editProfile ? false : true}
                                name="organization"
                                onChange={setOrganization}
                                placeholder={t(
                                  "CompleteProf.Join_the_Circle_1_Org"
                                )}
                                required
                                options={allOrg}
                                isSearchable={true}
                                components={
                                  !editProfile && {
                                    Menu: () => null,
                                    MenuList: () => null,
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null,
                                  }
                                }
                              />
                              {organization.value == "Others" && (
                                <textarea
                                  required
                                  disabled={editProfile ? false : true}
                                  className="complete_profile_6_textarea"
                                  placeholder={t(
                                    "CompleteProf.complete_your_profile_37"
                                  )}
                                  value={newInst}
                                  style={{ marginTop: "10px" }}
                                  onChange={(e) => setNewInst(e.target.value)}
                                />
                              )}
                            </>
                          )}
                        </div>
                      </div>

                      <h2 className="title-2">
                        {t("CompleteProf.volunteering_time")}{" "}
                      </h2>
                      <hr className="pink-hr2" />

                      <div className="input_row">
                        <div className="row-fiels">
                          <label className="profile_info_labels">
                            {t("CompleteProf.frequency")}
                          </label>
                          <Select
                            value={frequency && frequency}
                            className="edit_profile_select"
                            disabled={editProfile ? false : true}
                            name="frequency"
                            onChange={setFrequency}
                            placeholder={t("CompleteProf.select_Freq")}
                            required
                            options={allFrequencies}
                            isSearchable={true}
                            components={
                              !editProfile && {
                                Menu: () => null,
                                MenuList: () => null,
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }
                            }
                          />
                        </div>
                        <div className="row-fiels"></div>
                      </div>
                      <div className="input_row">
                        <div className="row-fiels">
                          <label className="profile_info_labels">
                            {t("CompleteProf.availability")}
                          </label>
                          <div className="complete_step6_all_avail">
                            {allAv &&
                              allAv?.map((avail) => {
                                return (
                                  <div className="complete_step6_avail">
                                    <input
                                      value={avail.id}
                                      type="checkbox"
                                      id={avail.id}
                                      name="availability"
                                      className="filter_checkbox"
                                      onChange={(e) =>
                                        handleCheck(
                                          e,
                                          avail.id == 7 ? true : false
                                        )
                                      }
                                      checked={
                                        availability.includes(avail.id) ||
                                        (text && avail.id == 7)
                                          ? "checked"
                                          : ""
                                      }
                                      disabled={editProfile ? false : true}
                                    />
                                    <input
                                      type="hidden"
                                      value={
                                        availability.includes(avail.id) ||
                                        (text && avail.id === 7)
                                      }
                                      required
                                    />
                                    <div className="complete_step4_sector_info">
                                      <label
                                        className="complete_step4_avail_name"
                                        for={"avail_radio" + avail.id}
                                      >
                                        {" "}
                                        {lang == "en"
                                          ? avail.name
                                          : avail.name_ar}
                                      </label>
                                    </div>
                                  </div>
                                );
                              })}

                            {text && (
                              <textarea
                                className="complete_profile_6_textarea"
                                required
                                placeholder={t(
                                  "CompleteProf.complete_your_profile_36"
                                )}
                                value={otherValue}
                                onChange={(e) => setOtherValue(e.target.value)}
                              />
                            )}
                          </div>
                        </div>
                        <div className="row-fiels">
                          <label className="profile_info_labels">
                            {t("CompleteProf.complete_your_profile_27")}
                          </label>
                          <div className="complete_step6_all_avail">
                            {allVol &&
                              allVol.map((avail1) => {
                                return (
                                  <div className="complete_step6_avail">
                                    <input
                                      required
                                      value={avail1.value}
                                      type="radio"
                                      id={"volunteered" + avail1.id}
                                      name="volunteered"
                                      className="avail_radio"
                                      onChange={(e) =>
                                        handleCheckReason(e, true)
                                      }
                                      checked={
                                        isVol == avail1.value ? "checked" : ""
                                      }
                                      disabled={editProfile ? false : true}
                                    />
                                    <div className="complete_step4_sector_info">
                                      <label
                                        className="complete_step4_avail_name"
                                        for={"avail_radio" + avail1.id}
                                      >
                                        {" "}
                                        {avail1.label}
                                      </label>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>

                          <label className="profile_info_labels">
                            {t("CompleteProf.complete_your_profile7_2")}
                          </label>

                          <div className="complete_step6_all_avail">
                            {allDates &&
                              allDates.map((avail2) => {
                                return (
                                  <div className="complete_step6_avail">
                                    <input
                                      value={avail2.value}
                                      type="radio"
                                      id={"dates_avail_radio" + avail2.id}
                                      name="date_available"
                                      className="avail_radio"
                                      required
                                      onChange={(e) => handleCheckdates(e)}
                                      disabled={editProfile ? false : true}
                                    />
                                    <div className="complete_step4_sector_info">
                                      <label
                                        className="complete_step4_avail_name"
                                        for={"dates_avail_radio" + avail2.id}
                                      >
                                        {" "}
                                        {avail2.label}
                                      </label>
                                    </div>
                                  </div>
                                );
                              })}
                            {anydate == "Any Date" && (
                              <>
                                <div className="complete_step3_header">
                                  Would you like to select an End date?
                                </div>
                                <div className="checkboxes_complete_prof">
                                  <div className="checkboxes_complete_prof_cont">
                                    <input
                                      type="radio"
                                      id="end_date_yes"
                                      name="end_date"
                                      value={openfirstcal}
                                      checked={openfirstcal ? true : false}
                                      className="checkboxes_complete "
                                      onChange={() => setopenfirstcal(true)}
                                      disabled={editProfile ? false : true}
                                    />
                                    <label for="end_date">Yes</label>
                                  </div>
                                  <div className="checkboxes_complete_prof_cont">
                                    <input
                                      type="radio"
                                      id="end_date_no"
                                      name="end_date"
                                      value={!openfirstcal}
                                      className="checkboxes_complete "
                                      checked={!openfirstcal ? true : false}
                                      onChange={() => setopenfirstcal(false)}
                                      disabled={editProfile ? false : true}
                                    />
                                    <label for="end_date">No</label>
                                  </div>
                                </div>
                                {openfirstcal && (
                                  <div className="complete_step3_header">
                                    <DatePicker
                                      id="end_date"
                                      colorPrimary="var(--primary)"
                                      value={endDate}
                                      onChange={setEndDate}
                                      renderInput={renderCustomInput2}
                                      shouldHighlightWeekends
                                      calendarClassName="responsive-calendar datepicker_cont "
                                      disabled={editProfile ? false : true}
                                    />
                                  </div>
                                )}
                              </>
                            )}
                            {anydate && anydate != "Any Date" && (
                              <div className="complete_step3_header">
                                <DatePicker
                                  id="start_date"
                                  colorPrimary="var(--primary)"
                                  value={startDate}
                                  onChange={setStartDate}
                                  renderInput={renderCustomInput1}
                                  shouldHighlightWeekends
                                  calendarClassName="responsive-calendar datepicker_cont "
                                  minimumDate={defaultDate}
                                  disabled={editProfile ? false : true}
                                />
                                <br />
                                <DatePicker
                                  id="end_date"
                                  colorPrimary="var(--primary)"
                                  value={endDate}
                                  onChange={setEndDate}
                                  renderInput={renderCustomInput2}
                                  shouldHighlightWeekends
                                  calendarClassName="responsive-calendar datepicker_cont "
                                  minimumDate={startDate}
                                  disabled={editProfile ? false : true}
                                />
                                {error && (
                                  <div className="error_msg">{error}</div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <h2 className="title-2">
                        {t("CompleteProf.volunteering_experience")}{" "}
                      </h2>
                      <hr className="pink-hr2" />

                      <div className="input_row">
                        <div className="row-fiels">
                          <label className="profile_info_labels">
                            {t("CompleteProf.reasons_of_volunteering")}
                          </label>
                          <div className="complete_step6_all_avail">
                            {allReasons &&
                              allReasons?.map((avail) => {
                                return (
                                  <div className="complete_step6_avail">
                                    <input
                                      value={avail.id}
                                      type="checkbox"
                                      id={"whyvol" + avail.id}
                                      name="whyvol"
                                      className="filter_checkbox"
                                      onChange={(e) =>
                                        handleCheckReason(e, false)
                                      }
                                      checked={
                                        reason.includes(avail.id)
                                          ? "checked"
                                          : ""
                                      }
                                      disabled={editProfile ? false : true}
                                    />
                                    <div className="complete_step4_sector_info">
                                      <label
                                        className="complete_step4_avail_name"
                                        for={"avail_radio" + avail.id}
                                      >
                                        {" "}
                                        {lang == "en"
                                          ? avail.name
                                          : avail.name_ar}
                                      </label>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>

                      <div className="input_row">
                        <div className="row-fiels">
                          <label className="profile_info_labels">
                            {t("CompleteProf.complete_your_profile_31")}
                          </label>
                          <textarea
                            className="profile_info_textarea"
                            placeholder={t(
                              "CompleteProf.complete_your_profile_31"
                            )}
                            value={objective}
                            required
                            onChange={(e) => setobjective(e.target.value)}
                            disabled={editProfile ? false : true}
                          />
                        </div>
                        <div className="row-fiels">
                          <label className="profile_info_labels">
                            {t("CompleteProf.complete_your_profile_33")}
                            <div className="icon-container">
                              <BsPatchQuestion size={20} className="popicon" />
                              <div className="tooltip">
                                {t("CompleteProf.share_comments")}
                              </div>
                            </div>
                          </label>
                          <textarea
                            className="profile_info_textarea"
                            placeholder={t(
                              "CompleteProf.complete_your_profile_33"
                            )}
                            value={comments}
                            required
                            onChange={(e) => setcomments(e.target.value)}
                            disabled={editProfile ? false : true}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="complete_step1_footer">
                  {editProfile && (
                    <button
                      type="submit"
                      className={
                        size.width > 768
                          ? "primary_button complete_step_2_next_button"
                          : "primary_button complete_step_2_next_button_resp"
                      }
                    >
                      {size.width > 768 ? (
                        t("oppdetails.oppdetails_3")
                      ) : lang == "en" ? (
                        <AiOutlineArrowRight size="1.5rem" />
                      ) : (
                        <AiOutlineArrowLeft size="1.5rem" />
                      )}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Profile_info;
