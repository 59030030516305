import "./ContactUsPage.css";
import big from "../../assets/images/main-banner-contact.jpg";
import phone from "../../assets/images/phone.svg";
import mail from "../../assets/images/mail.svg";
import { useDispatch } from "react-redux";
import { createContactus } from "../../Redux/contactus/contactusActions";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import Uppersection from "../../components/UpperSection/UpperSection.js";
import bigar from "../../assets/images/main-banner-contactar.jpg";

function ContactUsPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  var reasons_for_inqueries = [
    "",
    "Volunteering",
    "Masterclass",
    "Volunteer Management services",
    "Job matching",
    "Partnerships",
  ];
  return (
    <div className="contact-page">
      <Uppersection title={t("ContactUs.title")} />
      {/* <p>{t("ContactUs.title")}</p> */}

      <div className="contactUs-container">
        {lang === "en" ? (
          <img src={big} className="big-img" alt="contact" />
        ) : (
          <img src={bigar} className="big-img" alt="contact" />
        )}

        <div className="blocks_container">
          <div className="blocks">
            <div className="phone-container">
              <img src={phone} className="phone-img" alt="phone" />
              <div className="phone-number"> {t("Footer.+961 70 115 999")}</div>
              <div className="phone-number"> {t("Footer.+961 81 736 424")}</div>
            </div>
            <div className="mail-container">
              <img src={mail} className="mail" alt="mail" />
              <div className="info">
                <p>{t("ContactUs.email")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pink-block">
        <div className="there">
          <div className="text_contactus_container"> {t("ContactUs.hi")} </div>
        </div>
        <div className="fields_contact_container">
          <div
            className={lang === "en" ? "input-fields" : "input-fields-arabic"}
          >
            <input
              type="text"
              className={lang === "en" ? "box" : "box-arabic"}
              placeholder={t("ContactUs.address")}
            />
            <input
              type="text"
              className={lang === "en" ? "box" : "box-arabic"}
              placeholder={t("ContactUs.name")}
            />
            <input
              type="text"
              className={lang === "en" ? "box" : "box-arabic"}
              placeholder={t("ContactUs.work")}
            />
            <input
              type="text"
              className={lang === "en" ? "box" : "box-arabic"}
              placeholder={t("ContactUs.think")}
            />
            <select
              name="payment"
              required
              className={lang === "en" ? "box" : "box-arabic"}
            >
              {reasons_for_inqueries.map((reason, index) => {
                return (
                  <option key={index} value={reason}>
                    {reason}
                  </option>
                );
              })}
            </select>
            <div>
              <button className="button">{t("ContactUs.submit")}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ContactUsPage;
