import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Header/mobileheader.css";
import logo from "../../assets/images/header/logo-black.svg";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Header from "./Header";
import isAuth from "../../Utils/isAuth";

function Mobileheader(props) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [islogedin, setislogedin] = useState(false);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const getrole = () => {
    const role = localStorage.getItem("role");
    setRole(role);
  };
  useEffect(() => {
    if (!isAuth()) {
      setislogedin(false);
    } else {
      setislogedin(true);
      getrole();
    }
  }, [isAuth()]);
  const handleLanguageChangear = () => {
    if (i18n.language === "en") {
      i18n.changeLanguage("ar");
      document.body.dir = "rtl";
      document.documentElement.setAttribute("lang", "ar");
      setLang("ar");
    } else {
      i18n.changeLanguage("en");
      document.body.dir = "ltr";
      document.documentElement.setAttribute("lang", "en");
      setLang("en");
    }
  };
  const handleLanguageChangeen = () => {
    if (i18n.language === "ar") {
      i18n.changeLanguage("en");
      document.body.dir = "rtl";
      document.documentElement.setAttribute("lang", "en");
      setLang("en");
    }
  };

  return (
    <div
      className={lang === "en" ? "Main_mobile_header" : "Main_mobile_header_ar"}
    >
      {/* {role == 3 && islogedin && (
        <nav>
          <div
            className={lang === "en" ? "logo_container" : "logo_container_ar"}
          >
            <div className="img_container">
              <img
                src={logo}
                className="logo_mobile"
                onClick={() => navigate("/")}
              />
            </div>
            <div
              className={
                lang === "ar"
                  ? "close_container_ar close_container"
                  : "close_container"
              }
            >
              <AiOutlineCloseCircle
                size={40}
                className="close_butt"
                onClick={() => props.setIsShown(false)}
              />
            </div>
          </div>

          <div className="collapse navbar-collapse" id="navbarNav">
            <div className={"choose_language"}>
              <span
                className="language-selected active-lang"
                onClick={handleLanguageChangeen}
              >
                {lang === "ar" ? "ع" : "EN"}
              </span>
              |
              <span
                className="language-selected-mobile"
                onClick={handleLanguageChangear}
              >
                {i18n.language === "en" ? "ع" : "EN"}
              </span>
            </div>

            <div className="navbar-nav">
              <div className="nav-item active">
                <Link className="nav-link" to="/opportunities">
                  {t("header.subs1")}
                </Link>
                <div className="dotter_spacer" />
              </div>
              <div className="nav-item">
                <Link className="nav-link" to="/communityAction">
                  {t("header.subs2")}
                </Link>
                <div className="dotter_spacer" />
              </div>
              <div className="nav-item">
                <Link className="nav-link" to="/masterclass">
                  {t("header.subs_m3")}
                </Link>
                <div className="dotter_spacer" />
              </div>
              
              <div className="nav-item">
                <Link className="nav-link" to="/mayors-of-impact">
                  {t("header.subs3")}
                </Link>
                <div className="dotter_spacer" />
              </div>
              <div className="nav-item">
                <Link className="nav-link" to="/companies-and-institutions">
                  {t("header.subs_m5")}
                </Link>
                <div className="dotter_spacer" />
              </div>
              <div className="nav-item">
                <Link className="nav-link" href="/">
                  {t("header.subs_m6")}
                </Link>
                <div className="dotter_spacer" />
              </div>
              <div className="nav-item">
                <Link className="nav-link" to="/volunteer-managers">
                  {t("header.subs6")}
                </Link>
                <div className="dotter_spacer" />
              </div>
              <div className="nav-item">
                <Link className="nav-link" to="/about-us">
                  {t("header.subs7")}
                </Link>
                <div className="dotter_spacer" />
              </div>
              <div className="nav-item">
                <Link className="nav-link" to="/our-partners">
                  {t("header.subs8")}
                </Link>
                <div className="dotter_spacer" />
              </div>
              <div className="nav-item">
                <Link className="nav-link" to="/profile">
                  {t("header.subs_m7")}
                </Link>
                <div className="dotter_spacer" />
              </div>
            </div>
          </div>
        </nav>
      )} */}

      {islogedin && role == 4 ? (
        <nav>
          <div className="logo_container">
            <div className="img_container">
              <img
                src={logo}
                className="logo_mobile"
                onClick={() => navigate("/")}
              />
            </div>
            <div
              className={
                lang === "ar"
                  ? "close_container_ar close_container"
                  : "close_container"
              }
            >
              <AiOutlineCloseCircle
                size={40}
                className="close_butt"
                onClick={() => props.setIsShown(false)}
              />
            </div>
          </div>

          <div className="collapse navbar-collapse" id="navbarNav">
            <div className={"choose_language"}>
              <span
                className="language-selected active-lang"
                onClick={handleLanguageChangeen}
              >
                {lang === "ar" ? "ع" : "EN"}
              </span>
              |
              <span
                className="language-selected-mobile"
                onClick={handleLanguageChangear}
              >
                {i18n.language === "en" ? "ع" : "EN"}
              </span>
            </div>

            <div className="navbar-nav">
              <div
                className="nav-item active"
                onClick={() => props.setIsShown(false)}
              >
                <Link className="nav-link" to="/opportunities/1">
                  {t("header.org_title1")}
                </Link>
                <div className="dotter_spacer" />
              </div>
              <div className="nav-item" onClick={() => props.setIsShown(false)}>
                <Link className="nav-link" to="/oppRequests">
                  {t("header.subs_m3")}
                </Link>
                <div className="dotter_spacer" />
              </div>
              <div className="nav-item" onClick={() => props.setIsShown(false)}>
                <Link className="nav-link" to="/checkin_out">
                  {t("header.subs_m2")}
                </Link>
                <div className="dotter_spacer" />
              </div>
              {/* <div className="nav-item">
            <a className="nav-link" href="#">
              {t("header.subs_m4")}
            </a>
            <div className="dotter_spacer" />
          </div> */}
              {/* <div className="nav-item" onClick={() => props.setIsShown(false)}>
                <Link className="nav-link" to="/">
                  {t("header.subs_m5")}
                </Link>
                <div className="dotter_spacer" />
              </div> */}
              {/* <div className="nav-item">
            <Link className="nav-link" href="/mayors-of-impact">
              {t("header.subs_m6")}
            </Link>
            <div className="dotter_spacer" />
          </div> */}
              <div className="nav-item" onClick={() => props.setIsShown(false)}>
                <Link className="nav-link" to="/masterClass">
                  {t("header.org_title3")}
                </Link>
                <div className="dotter_spacer" />
              </div>
            </div>
          </div>
        </nav>
      ) : (
        <nav>
          <div className="logo_container">
            <div className="img_container">
              <img
                src={logo}
                className="logo_mobile"
                onClick={() => navigate("/")}
              />
            </div>
            <div
              className={
                lang === "ar"
                  ? "close_container_ar close_container"
                  : "close_container"
              }
            >
              <AiOutlineCloseCircle
                size={40}
                className="close_butt"
                onClick={() => props.setIsShown(false)}
              />
            </div>
          </div>

          <div className="collapse navbar-collapse" id="navbarNav">
            <div className={"choose_language"}>
              <span
                className="language-selected active-lang"
                onClick={handleLanguageChangeen}
              >
                {lang === "ar" ? "ع" : "EN"}
              </span>
              |
              <span
                className="language-selected-mobile"
                onClick={handleLanguageChangear}
              >
                {i18n.language === "en" ? "ع" : "EN"}
              </span>
            </div>

            <div className="navbar-nav">
              <div
                className="nav-item active"
                onClick={() => props.setIsShown(false)}
              >
                <Link className="nav-link" to="/opportunities/1">
                  {t("header.subs1")}
                </Link>
                <div className="dotter_spacer" />
              </div>

              {/* <div className="nav-item" onClick={() => props.setIsShown(false)}>
                <Link className="nav-link" to="/oppRequests">
                  {t("header.subs_m3")}
                </Link>
                <div className="dotter_spacer" />
              </div> */}
              {/* <div className="nav-item">
                <a className="nav-link" href="#">
                  {t("header.subs3")}
                </a>
                <div className="dotter_spacer" />
              </div> */}

              {islogedin && (
                <div
                  className="nav-item"
                  onClick={() => props.setIsShown(false)}
                >
                  <Link className="nav-link" to="/communityAction">
                    {t("header.subs11")}
                  </Link>
                  <div className="dotter_spacer" />
                </div>
              )}

              <div className="nav-item" onClick={() => props.setIsShown(false)}>
                <Link className="nav-link" to="/mayors-of-impact">
                  {!islogedin ? t("header.subs3") : t("header.subs33")}
                </Link>
                <div className="dotter_spacer" />
              </div>
              {!islogedin && (
                <div
                  className="nav-item"
                  onClick={() => props.setIsShown(false)}
                >
                  <Link className="nav-link" to="/companies-and-institutions/1">
                    {t("header.subs_m5")}
                  </Link>
                  <div className="dotter_spacer" />
                </div>
              )}
              {/* <div className="nav-item" onClick={() => props.setIsShown(false)}>
                <Link className="nav-link" to="/masterClass">
                  {t("header.subs_m6")}
                </Link>
                <div className="dotter_spacer" />
              </div> */}
              {!islogedin && (
                <div
                  className="nav-item"
                  onClick={() => props.setIsShown(false)}
                >
                  <Link className="nav-link" to="/volunteer-managers">
                    {t("header.subs6")}
                  </Link>
                  <div className="dotter_spacer" />
                </div>
              )}
              {!islogedin && (
                <div
                  className="nav-item"
                  onClick={() => props.setIsShown(false)}
                >
                  <Link className="nav-link" to="/companies-and-institutions/2">
                    {t("header.subs9")}
                  </Link>
                  <div className="dotter_spacer" />
                </div>
              )}
              {!islogedin && (
                <div
                  className="nav-item"
                  onClick={() => props.setIsShown(false)}
                >
                  <Link className="nav-link" to="/companies-and-institutions/1">
                    {t("header.subs10")}
                  </Link>
                  <div className="dotter_spacer" />
                </div>
              )}

              <div className="nav-item" onClick={() => props.setIsShown(false)}>
                <Link className="nav-link" to="/about-us">
                  {t("header.subs7")}
                </Link>
                <div className="dotter_spacer" />
              </div>
              {/* <div className="nav-item" onClick={() => props.setIsShown(false)}>
                <Link className="nav-link" to="/our-partners">
                  {t("header.subs8")}
                </Link>
                <div className="dotter_spacer" />
              </div> */}
              {/* <div className="nav-item" onClick={() => props.setIsShown(false)}>
                <Link className="nav-link" to="/profile">
                  {t("header.subs_m7")}
                </Link>
                <div className="dotter_spacer" />
              </div> */}
            </div>
          </div>
        </nav>
      )}
    </div>
  );
}

export default Mobileheader;
