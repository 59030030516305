import React from "react";
import CompleteProfile from "../../CompleteProfile";
import "./Complete_Step3.css";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import i18n from "../../../../i18n";
import { useEffect } from "react";
import { BiSave, BiSearchAlt2 } from "react-icons/bi";
import Select from "react-select";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineClose,
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  getuserinfo,
  updateUserinfo,
} from "../../../../Redux/user/userActions";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../components/Loading/Loading";
function Complete_Step3() {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const navigate = useNavigate();
  const size = useWindowSize();
  const [isNext, setIsNext] = useState(true);
  const token = localStorage.getItem("token");
  const { userinfo } = useSelector((store) => store.userReducer);
  const [volSkills, setVolSkills] = useState();
  const dispatch = useDispatch();
  const [dontAskMeSkills, setDontAskMeSkills] = useState({});
  const [skillsToLearn, setSkillsToLearn] = useState({});

  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const [skills, setSkills] = useState([]);

  const [allSkills, setAllSkills] = useState();

  const setSelectedSkills = (event) => {
    if (event.length > 0) {
      var found = false;
      if (skills.length > 0)
        for (var i = 0; i < skills.length; i++) {
          if (event[0].value == skills[i].value) {
            found = true;
          }
        }
      if (!found) setSkills((skill) => [...skill, event[0]]);
    }
  };

  const removeSkill = (index) => {
    const newfields = [...skills];
    newfields.splice(index, 1);
    setSkills(newfields);
  };

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  const getSkills = async () => {
    const response = await axios.get(process.env.REACT_APP_API + "/skills");
    const newS = [];
    response.data.data.map((d, i) => {
      if (d.type == "Soft skills" || d.type == "technical skills") newS.push(d);
    });
    const newSkills = newS.map((d, i) => {
      return {
        value: d.id,
        label: lang == "en" ? d.name : d.name_ar,
        color: d.type == "Soft skills" ? "blue" : "red",
      };
    });

    setAllSkills(newSkills);
  };

  const getVolSkills = async () => {
    const resp = await axios.get(
      process.env.REACT_APP_API + "/volunteer_skills",
      {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    );
    setVolSkills(resp.data.data);
  };

  useEffect(() => {
    getSkills();
    getVolSkills();
  }, []);

  useEffect(() => {
    if (volSkills && volSkills.length > 0 && allSkills) {
      var vS = [];
      for (var i = 0; i < volSkills.length; i++) {
        vS.push(
          allSkills.filter(
            (s) => s.value.toString() == volSkills[i].skill_id
          )[0]
        );
      }

      setSkills(vS);
    }
  }, [volSkills, allSkills]);

  useEffect(() => {
    if (userinfo != undefined) {
      let notNeededSkills_array = [];
      let neededSkills_array = [];

      userinfo?.skillsNeeded?.map((skill) => {
        neededSkills_array.push(
          lang === "en"
            ? {
                value: skill?.skill_id,
                label: skill?.["skill.name"],
                color: skill?.["skill.type"] == "Soft skills" ? "blue" : "red",
              }
            : {
                value: skill?.skill_id,
                label: skill?.["skill.name_ar"],
                color: skill?.["skill.type"] == "Soft skills" ? "blue" : "red",
              }
        );
      });

      userinfo?.skillsNotNeeded?.map((skill) => {
        notNeededSkills_array.push(
          lang === "en"
            ? {
                value: skill?.skill_id,
                label: skill?.["skill.name"],
                color: skill?.["skill.type"] == "Soft skills" ? "blue" : "red",
              }
            : {
                value: skill?.skill_id,
                label: skill?.["skill.name_ar"],
                color: skill?.["skill.type"] == "Soft skills" ? "blue" : "red",
              }
        );
      });

      setSkillsToLearn(neededSkills_array);
      setDontAskMeSkills(notNeededSkills_array);
    }
  }, [userinfo]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (skills) {
      const step = localStorage.getItem("step");
      if (step == 2) {
        localStorage.setItem("step", 3);
      }
      const newSkills = skills.map((d, i) => {
        return d.value.toString();
      });

      await axios.post(
        process.env.REACT_APP_API + "/volunteer_skills",
        {
          skill_id: newSkills,
        },
        { Authorization: "Bearer " + localStorage.getItem("token") }
      );

      const newDontAskMeSkills = dontAskMeSkills.map((d, i) => {
        return d.value.toString();
      });

      await axios.post(
        process.env.REACT_APP_API + "/volunteer_notNeeded_skills",
        {
          skill_id: newDontAskMeSkills,
        },
        { Authorization: "Bearer " + localStorage.getItem("token") }
      );

      const newSkillsToLearn = skillsToLearn.map((d, i) => {
        return d.value.toString();
      });

      await axios.post(
        process.env.REACT_APP_API + "/volunteer_needed_skills",
        {
          skill_id: newSkillsToLearn,
        },
        { Authorization: "Bearer " + localStorage.getItem("token") }
      );

      if (isNext) navigate("/completeProfile/step4");
      else navigate("/profile-info");
    }
  };

  return (
    <div className="complete_profile_step1_main_cont">
      <CompleteProfile
        header={t("CompleteProf.Join_the_Circle_3_Title")}
        body={
          !allSkills ? (
            <Loading />
          ) : (
            <form
              className="complete_step3_main_body"
              onSubmit={(e) => handleSubmit(e)}
            >
              <div>
                <div className="complete_step3_header">
                  <p
                    className="complete_input_header"
                    style={{ marginBottom: "1rem" }}
                  >
                    {t("CompleteProf.complete_your_profile_17")}
                    <br />
                    <span className="complete_step3_subheader">
                      {t("CompleteProf.complete_your_profile_17_2")}
                    </span>
                  </p>
                </div>

                <Select
                  value={null}
                  className="complete_profile_select complete_step3_search"
                  name="skills"
                  onChange={(e) => setSelectedSkills(e)}
                  placeholder={t("CompleteProf.search")}
                  isMulti={true}
                  options={allSkills}
                />
                <div className="complete_step3_skills_cont">
                  {skills &&
                    skills.map((skill, index) => {
                      return (
                        <div className="complete_step3_skills_input_cont">
                          <div
                            className="complete_step3_skills_input"
                            style={{ borderColor: skill.color }}
                          >
                            {" "}
                            {skill.label}{" "}
                          </div>{" "}
                          <AiOutlineClose
                            className="complete_step2_close"
                            size="1.2rem"
                            onClick={() => removeSkill(index)}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>

              <div>
                <label className="profile_info_labels">
                  {t("CompleteProf.dont_ask_me")}
                </label>
                <Select
                  placeholder={t("CompleteProf.select_skills")}
                  value={dontAskMeSkills}
                  isMulti
                  options={allSkills}
                  onChange={(e) => setDontAskMeSkills(e)}
                />
              </div>

              <div>
                <label className="profile_info_labels">
                  {t("CompleteProf.skills_to_learn")}
                </label>
                <Select
                  value={skillsToLearn}
                  isMulti
                  options={allSkills}
                  onChange={(e) => setSkillsToLearn(e)}
                  placeholder={t("CompleteProf.select_skills")}
                />
              </div>

              <div className="complete_step1_footer">
                <div className="divider_line"></div>
                <div className="complete_step_footer_button">
                  <button
                    className={
                      size.width > 768
                        ? "primary_button complete_step_1_next_button"
                        : "primary_button complete_step_2_next_button_resp"
                    }
                    type="button"
                    onClick={() => navigate("/completeProfile/step2")}
                  >
                    {size.width > 768 ? (
                      t("CompleteProf.complete_your_profile_btn_5")
                    ) : lang == "en" ? (
                      <AiOutlineArrowLeft size="1.5rem" />
                    ) : (
                      <AiOutlineArrowRight size="1.5rem" />
                    )}
                  </button>

                  <div
                    className={
                      size.width > 768
                        ? "complete_step_right_footer"
                        : "complete_step_right_footer_resp"
                    }
                  >
                    <button
                      className={
                        size.width > 500
                          ? "pink_button complete_step_2_complete_button"
                          : "pink_button complete_step_2_next_button_resp"
                      }
                      onClick={() => setIsNext(false)}
                    >
                      {size.width > 500 ? (
                        t("CompleteProf.complete_your_profile_btn_3")
                      ) : (
                        <BiSave size="1.5rem" />
                      )}
                    </button>
                    <button
                      className={
                        size.width > 768
                          ? "primary_button complete_step_2_next_button"
                          : "primary_button complete_step_2_next_button_resp"
                      }
                      onClick={() => setIsNext(true)}
                    >
                      {size.width > 768 ? (
                        t("CompleteProf.complete_your_profile_btn_4")
                      ) : lang == "en" ? (
                        <AiOutlineArrowRight size="1.5rem" />
                      ) : (
                        <AiOutlineArrowLeft size="1.5rem" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )
        }
        footer={
          <div
            className={
              lang == "en"
                ? "complete_profile_footer_slider_green"
                : " complete_profile_footer_slider_green complete_profile_footer_slider_green_ar"
            }
            style={{ width: "30%" }}
          ></div>
        }
      />
    </div>
  );
}

export default Complete_Step3;
