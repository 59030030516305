import React from "react";
import CompleteProfile from "../../CompleteProfile";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import i18n from "../../../../i18n";
import { useEffect } from "react";
import "./Complete_step1.css";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";

//date picker
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";

import {
  updateUserinfo,
  getuserinfo,
} from "../../../../Redux/user/userActions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import Loading from "../../../../components/Loading/Loading";
import { getAllHearingAbtTvc } from "../../../../Redux/Hearing_about_tvc/HearingAboutTvcActions";

function Complete_Step1() {
  const { userinfo, loading } = useSelector((store) => store.userReducer);
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [fName, setFName] = useState();
  const [lName, setLName] = useState("");
  const [nationality, setNationality] = useState(null);
  const [birthDate, setBirthDate] = useState(null);
  const [country, setCountry] = useState(null);
  const [linkedIn, setLinkedIn] = useState("");
  const [gender, setGender] = useState("");
  const [allCountries, setAllCountries] = useState({});
  const [allNationalities, setAllNationalities] = useState({});
  const [error, setError] = useState("");
  const [phone, setphone] = useState("");
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const size = useWindowSize();
  const [selectedGov, setSelectedGov] = useState();
  const [districts, setDistricts] = useState();
  const [governate, setGovernate] = useState();
  const [selectedDis, setSelectedDis] = useState();
  const [counter, setCounter] = useState(0);
  const [allHearings, setAllHearings] = useState([]);
  const [hearing, setHearing] = useState(null);
  const { hearingAboutTvc } = useSelector(
    (store) => store.HearingAboutTvcReducer
  );
  const [allDisabilities, setAllDisabilities] = useState();
  const [disabilities, setDisabilities] = useState({});

  const getDisabilities = async () => {
    const response = await axios.get(
      process.env.REACT_APP_API + "/disabilities"
    );

    const newDisabilities = response.data.data.map((d, i) => {
      return { value: d.id, label: lang == "en" ? d.text : d.text_ar };
    });
    setAllDisabilities(newDisabilities);
  };

  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const asyncfunction = async () => {
    const resp_countries = await axios.get(
      process.env.REACT_APP_API + "/countries"
    );

    const countries = resp_countries.data.data.map((d, i) => {
      return { value: d.id, label: lang == "en" ? d.name : d.name_ar };
    });

    setAllCountries(countries);

    const resp_nat = await axios.get(
      process.env.REACT_APP_API + "/nationalities"
    );

    const nationalities = resp_nat.data.data.map((d, i) => {
      return { value: d.id, label: lang == "en" ? d.name : d.name_ar };
    });

    setAllNationalities(nationalities);
  };
  const maxDate = {
    day: new Date().getDate(),
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear() - 13,
  };

  useEffect(() => {
    asyncfunction();
  }, [lang]);

  useEffect(() => {
    dispatch(getAllHearingAbtTvc());
    dispatch(getuserinfo(token));
    getDisabilities();
    asyncfunction();
  }, []);

  useEffect(() => {
    if (hearingAboutTvc) {
      const newHearings = hearingAboutTvc.map((d, i) => {
        return {
          value: d.name,
          label: lang == "en" ? d.name : d.name_ar,
          id: d.id,
        };
      });

      setAllHearings(newHearings);
    }
  }, [hearingAboutTvc]);

  useEffect(() => {
    if (userinfo) {
      setFName(userinfo.first_name);
      setLName(userinfo.last_name);
      if (allNationalities && allNationalities.length > 0) {
        for (var i = 0; i < allNationalities.length; i++) {
          if (allNationalities[i].value == userinfo.nationality_id) {
            setNationality(allNationalities[i]);
          }
        }
      }

      if (allHearings && allHearings.length > 0) {
        for (var i = 0; i < allHearings.length; i++) {
          if (allHearings[i].id == userinfo?.hearing_abt_tvc_id) {
            setHearing(allHearings[i]);
          }
        }
      }

      if (userinfo.country_out_lebanon) {
        var newC = {
          value: userinfo.country_out_lebanon,
          label:
            lang == "en"
              ? userinfo.country_name_out_lebanon
              : userinfo.country_name_ar_out_lebanon,
        };

        setCountry(newC);
        setCounter(1);
      } else {
        if (userinfo.district_id && userinfo.governate_id) {
          var newDis = {
            value: userinfo.district_id,
            label:
              lang == "en" ? userinfo.district_name : userinfo.district_name_ar,
          };

          setSelectedDis(newDis);

          var newC = {
            value: 1,
            label: lang == "en" ? "Lebanon" : "لبنان",
          };

          setCountry(newC);

          var newGov = {
            value: userinfo.governate_id,
            label:
              lang == "en"
                ? userinfo.governate_name
                : userinfo.governate_name_ar,
          };

          setSelectedGov(newGov);

          setCounter(1);
        }
      }
      if (userinfo.date_of_birth) {
        const date = new Date(userinfo.date_of_birth);
        const newdate = {
          day: date.getDate(),
          month: date.getMonth() + 1,
          year: date.getFullYear(),
        };
        setBirthDate(newdate);
      }
      setLinkedIn(userinfo.linked_in);
      setGender(userinfo.gender);
      const gen = document.getElementById(userinfo.gender);
      if (gen) {
        gen.checked = true;
      }

      if (userinfo.phone_number) {
        setphone(userinfo.phone_number);
      }
    }
  }, [userinfo, allCountries, allNationalities]);

  useEffect(() => {
    if (userinfo != undefined) {
      let disabilities_array = [];

      userinfo?.disabilities?.map((disability) => {
        disabilities_array.push(
          lang === "en"
            ? {
                value: disability?.disability_id,
                label: disability?.disability?.text,
              }
            : {
                value: disability?.disability_id,
                label: disability?.disability?.text_ar,
              }
        );
      });

      setDisabilities(disabilities_array);
    }
  }, [userinfo]);

  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      className="complete_profile_input complete_date_input"
      placeholder="Date of Birth"
      value={
        birthDate &&
        birthDate.day + "-" + birthDate.month + "-" + birthDate.year
      }
    />
  );

  const onSubmit = async (e) => {
    e.preventDefault();

    const date_of_birth = new Date();
    if (birthDate != null) {
      date_of_birth.setDate(birthDate.day);
      date_of_birth.setMonth(birthDate.month);
      date_of_birth.setFullYear(birthDate.year);
    }
    if (fName && lName && nationality && birthDate && gender && phone) {
      const step = localStorage.getItem("step");
      if (step == 0) {
        localStorage.setItem("step", 1);
      }
      if (country?.value == 1) {
        const user = {
          first_name: fName,
          last_name: lName,
          nationality_id: nationality.value,
          district_id: selectedDis.value,
          count_id: null,
          date_of_birth: date_of_birth,
          gender: gender,
          linked_in: linkedIn ? linkedIn : null,
          phone_number: phone,
          hearing_abt_tvc_id: hearing?.id,
        };
        dispatch(updateUserinfo(token, user, navigate, 2));
      } else {
        const user = {
          first_name: fName,
          last_name: lName,
          district_id: null,
          nationality_id: nationality.value,
          count_id: country.value,
          date_of_birth: date_of_birth,
          gender: gender,
          linked_in: linkedIn ? linkedIn : null,
          phone_number: phone,
          hearing_abt_tvc_id: hearing?.id,
        };
        dispatch(updateUserinfo(token, user, navigate, 2));
      }

      // dispatch(updateUserinfo(token, user, navigate, 2));
    } else setError("Please enter all required fields");

    const newDisabilities = disabilities.map((d, i) => {
      return d.value.toString();
    });

    await axios.post(
      process.env.REACT_APP_API + "/volunteer_disabilities",
      {
        disability_id: newDisabilities,
      },
      { Authorization: "Bearer " + localStorage.getItem("token") }
    );
  };

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  const getGov = async (id) => {
    const response = await axios.get(
      process.env.REACT_APP_API + "/governates/getGovernateByCountry/" + id
    );

    const newGov = response.data.data.map((d, i) => {
      return {
        value: d.id,
        label: lang == "en" ? d.name : d.name_ar,
      };
    });

    setGovernate(newGov.filter((s) => s));
  };

  const getDis = async (id) => {
    const response = await axios.get(
      process.env.REACT_APP_API + "/districts/getDistrictByGovernate/" + id
    );

    const newDis = response.data.data.map((d, i) => {
      return {
        value: d.id,
        label: lang == "en" ? d.name : d.name_ar,
      };
    });
    setDistricts(newDis.filter((s) => s));
  };

  useEffect(() => {
    setCounter(counter + 1);
    if (country?.value != undefined) getGov(country?.value);

    if (counter > 1) {
      setSelectedGov("");
      setSelectedDis("");
    }
  }, [country]);

  useEffect(() => {
    if (selectedGov?.value !== undefined) {
      getDis(selectedGov?.value);
    }
    if (counter == 1 && userinfo?.governate_id != selectedGov?.value) {
      setSelectedDis("");
    }

    if (counter > 1) {
      setSelectedDis("");
    }
  }, [selectedGov]);

  return (
    <div className="complete_profile_step1_main_cont">
      <CompleteProfile
        header={t("CompleteProf.complete_your_profile_title")}
        subtitle={t("CompleteProf.complete_your_profile_1")}
        steps={1}
        body={
          loading ? (
            <Loading />
          ) : (
            <form
              className="complete_step1_main_body"
              onSubmit={(e) => onSubmit(e)}
            >
              <div className="complete_step1_body1">
                <input
                  className="complete_profile_input"
                  type="text"
                  required
                  value={fName}
                  onChange={(e) => setFName(e.target.value)}
                  placeholder={t("CompleteProf.complete_your_profile_2")}
                />
                <input
                  className="complete_profile_input"
                  type="text"
                  required
                  value={lName}
                  onChange={(e) => setLName(e.target.value)}
                  placeholder={t("CompleteProf.complete_your_profile_3")}
                />
                <Select
                  value={nationality && nationality}
                  className="complete_profile_select"
                  name="nationality"
                  onChange={setNationality}
                  placeholder={t("CompleteProf.complete_your_profile_4")}
                  required
                  options={allNationalities}
                  isSearchable={true}
                />
                <DatePicker
                  colorPrimary="var(--primary)"
                  value={birthDate ? birthDate : maxDate}
                  onChange={setBirthDate}
                  renderInput={renderCustomInput}
                  shouldHighlightWeekends
                  calendarClassName="responsive-calendar datepicker_cont "
                  maximumDate={maxDate}
                  required
                />
                <PhoneInput
                  country={"lb"}
                  value={phone}
                  required={true}
                  onChange={(e) => setphone(e)}
                  placeholder="Phone Number"
                  countryCodeEditable={false}
                />
              </div>

              <div className="complete_step1_body2">
                <div className="complete_radio_comp">
                  <input
                    onChange={(e) => setGender(e.target.value)}
                    className={
                      lang == "en"
                        ? "complete_radio_btn"
                        : "complete_radio_btn_ar"
                    }
                    type="radio"
                    id="male"
                    name="gender"
                    value="Male"
                    checked={gender == "Male" ? "checked" : ""}
                  />
                  <label for="male" style={{ cursor: "pointer" }}>
                    {" "}
                    {t("CompleteProf.complete_your_profile_7")}
                  </label>
                </div>
                <div className="complete_radio_comp">
                  <input
                    onChange={(e) => setGender(e.target.value)}
                    className={
                      lang == "en"
                        ? "complete_radio_btn"
                        : "complete_radio_btn_ar"
                    }
                    type="radio"
                    id="female"
                    name="gender"
                    value="Female"
                    checked={gender == "Female" ? "checked" : ""}
                  />
                  <label for="female" style={{ cursor: "pointer" }}>
                    {t("CompleteProf.complete_your_profile_8")}
                  </label>
                </div>
                <div className="complete_radio_comp">
                  <input
                    onChange={(e) => setGender(e.target.value)}
                    className={
                      lang == "en"
                        ? "complete_radio_btn"
                        : "complete_radio_btn_ar"
                    }
                    type="radio"
                    id="non_binary"
                    name="gender"
                    value="Non-Binary"
                    checked={gender == "Non-Binary" ? "checked" : ""}
                  />
                  <label for="non_binary" style={{ cursor: "pointer" }}>
                    {t("CompleteProf.complete_your_profile_9")}
                  </label>
                </div>
              </div>

              <br />

              <div>
                <div>
                  <label className="profile_info_labels">
                    {t("CompleteProf.hearing_abt_tvc")}
                  </label>
                  <Select
                    value={hearing && hearing}
                    name="hearings"
                    className="complete_profile_select"
                    placeholder={t("CompleteProf.select")}
                    required
                    isSearchable={true}
                    options={allHearings}
                    onChange={(e) => {
                      setHearing(e);
                    }}
                  />
                </div>
              </div>

              <br />

              <div className="row-fiels">
                <label className="profile_info_labels">
                  {t("CompleteProf.disabilities_question")}
                </label>
                <Select
                  value={disabilities}
                  isMulti
                  options={allDisabilities}
                  className="complete_profile_select complete_step3_search"
                  onChange={(e) => setDisabilities(e)}
                  placeholder={t("CompleteProf.select_what_applies")}
                  required
                />
              </div>

              <div className="complete_step1_body3">
                <p className="complete_input_header2">
                  {t("CompleteProf.complete_your_profile_11_loc")}*
                  <br />
                </p>
                <div className="complete_step1_location">
                  <Select
                    value={country && country}
                    className="complete_profile_select"
                    name="country"
                    onChange={setCountry}
                    placeholder={t("CompleteProf.complete_your_profile_5")}
                    required
                    isSearchable={true}
                    options={allCountries}
                  />
                  {country && country?.value == 1 && governate && (
                    <Select
                      value={selectedGov && selectedGov}
                      className="complete_profile_select"
                      name="nationality"
                      onChange={setSelectedGov}
                      placeholder={t("opportunity.create.governate")}
                      required={country?.value == 1 ? true : false}
                      options={governate}
                    />
                  )}

                  {country?.value == 1 && districts && (
                    <Select
                      value={selectedDis && selectedDis}
                      className="complete_profile_select"
                      name="nationality"
                      onChange={setSelectedDis}
                      placeholder={t("opportunity.create.district")}
                      required={country?.value == 1 ? true : false}
                      options={districts}
                    />
                  )}
                </div>
              </div>
              <div className="complete_step1_body4">
                <p className="complete_input_header">
                  {t("CompleteProf.complete_your_profile_10")}
                </p>
                <div className="complete_input_sub_header">
                  {t("CompleteProf.complete_your_profile_11")}
                </div>
                <input
                  className="complete_profile_input"
                  type="text"
                  value={linkedIn}
                  onChange={(e) => setLinkedIn(e.target.value)}
                  placeholder={t("CompleteProf.complete_your_profile_10")}
                />
              </div>
              {error && <div className="register_error">{error}</div>}
              <div className="complete_step1_footer">
                <div className="divider_line"></div>

                <button
                  className={
                    size.width > 768
                      ? "primary_button complete_step_2_next_button"
                      : "primary_button complete_step_2_next_button_resp"
                  }
                >
                  {size.width > 768 ? (
                    t("CompleteProf.complete_your_profile_btn_4")
                  ) : lang == "en" ? (
                    <AiOutlineArrowRight size="1.5rem" />
                  ) : (
                    <AiOutlineArrowLeft size="1.5rem" />
                  )}
                </button>
              </div>
            </form>
          )
        }
        footer={
          <div
            className={
              lang == "en"
                ? "complete_profile_footer_slider_green"
                : "complete_profile_footer_slider_green complete_profile_footer_slider_green_ar"
            }
            style={{ width: "10%" }}
          ></div>
        }
      />
    </div>
  );
}

export default Complete_Step1;
