import React from "react";
import "./CompleteOrg_Step3.css";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import i18n from "../../../../i18n";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CompleteProfile from "../../CompleteProfile";
import axios from "axios";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import {
  getSectors,
  getuserinfo,
  updateOrganizationSectors,
  updateUserinfo,
} from "../../../../Redux/user/userActions";
import { useDispatch, useSelector } from "react-redux";
import Popup from "../../../../components/PopUp/PopUp.js";
import { GiConsoleController } from "react-icons/gi";
import Loading from "../../../../components/Loading/Loading";

function CompleteOrg_Step3() {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allSectors, setAllSectors] = useState([]);
  const size = useWindowSize();
  const [isNext, setIsNext] = useState(true);
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [nameAr, setNameAr] = useState("");
  const [descAr, setDescAr] = useState("");

  const [error, setError] = useState(null);
  const [sectorsDatabase, setSectorsDatabase] = useState([]);
  let d;
  const getAllSectors = async () => {
    const response = await axios.get(process.env.REACT_APP_API + "/sectors");

    setAllSectors(response.data.data);
  };
  const token = localStorage.getItem("token");

  const { userinfo, loading } = useSelector((store) => store.userReducer);

  const { sectors, sectorLoading } = useSelector((store) => store.userReducer);

  useEffect(() => {
    setSectorsDatabase(sectors);
  }, [sectors]);

  const [openPopUp, setOpenPopUp] = useState(false);
  useEffect(() => {
    getAllSectors();
    dispatch(getSectors(token));
    dispatch(getuserinfo(token));
  }, []);

  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (userinfo && allSectors.length > 0) {
      const ids = userinfo.sectors?.map((sector) => sector.sector_id) || [];
  
      ids.forEach((id) => {
        const checkbox = document.getElementById("sector_check" + id);
        if (checkbox) {
          checkbox.checked = true;
        }
      });
  
      setSelectedSect(allSectors.filter((sector) => ids.includes(sector.id)));
  
      if (ids.length === allSectors.length) {
        const selectAllCheckbox = document.querySelector(".sector_check_all");
        if (selectAllCheckbox) {
          selectAllCheckbox.checked = true;
        }
      }
    }
  }, [userinfo, allSectors, !loading, !sectorLoading]);
  
  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  const [selectedSect, setSelectedSect] = useState([]);

  const handleEvent = (e, sector) => {
    if (e.target.checked) {
      setSelectedSect((element) => [...element, sector]);

      if (selectedSect.length + 1 == allSectors.length) {
        document.getElementsByClassName("sector_check_all")[0].checked = true;
      }
    } else {
      setSelectedSect(selectedSect.filter((s) => s.id != sector.id));
      document.getElementsByClassName("sector_check_all")[0].checked = false;
    }
  };

  const selectAll = (e) => {
    if (e.target.checked) {
      setSelectedSect(allSectors);

      var checked = document.getElementsByClassName("sector_check");
      for (let item of checked) {
        item.checked = true;
      }
    } else {
      setSelectedSect([]);
      var checked = document.getElementsByClassName("sector_check");
      for (let item of checked) {
        item.checked = false;
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedSect.length > 0) {
      const step = localStorage.getItem("step");

      if (step == 2) {
        localStorage.setItem("step", 3);
      }
      setError(null);
      const newSect = selectedSect.map((d, i) => {
        return d.id;
      });

      if (isNext)
        dispatch(updateOrganizationSectors(token, newSect, navigate, 4));
      else dispatch(updateOrganizationSectors(token, newSect, navigate, 0));
    } else {
      setError("Please select all required fields");
    }
  };

  const showPop = (index) => {
    setOpenPopUp(true);
    if (lang == "en") {
      setName(allSectors[index].name);
      setDesc(allSectors[index].description);
    } else {
      setNameAr(allSectors[index].name_ar);
      setDescAr(allSectors[index].description_ar);
    }
  };

  useEffect(() => {
    if (!openPopUp) {
      setName("");
      setDesc("");
    }
  }, [openPopUp]);

  return (
    <div className="complete_profile_step1_main_cont">
      <CompleteProfile
        header={t("CompleteProf.Join_the_Circle_3_Title")}
        body={
          loading ? (
            <Loading />
          ) : (
            <form
              className="complete_step3_main_body"
              onSubmit={(e) => handleSubmit(e)}
            >
              <div>
                <div className="complete_step3_header">
                  <p
                    className="complete_input_header"
                    style={{ marginBottom: "1rem" }}
                  >
                    {t("CompleteProf.complete_your_profile_18_0")}
                    <br />
                    {/* <span className="complete_step3_subheader">
                      {t("CompleteProf.complete_your_profile_19")}
                    </span> */}
                  </p>
                </div>

                <div className="complete_step4_sectors_cont">
                  {" "}
                  <div className="complete_step4_sector">
                    <input
                      type="checkbox"
                      id="sector_check"
                      className="sector_check_all"
                      name="all"
                      onChange={(e) => selectAll(e)}
                    />
                    <div className="complete_step4_sector_info">
                      <label className="complete_step4_sector_name" for="all">
                        {t("CompleteProf.all")}
                      </label>
                    </div>
                  </div>
                  {sectorLoading ? (
                    <Loading />
                  ) : (
                    allSectors &&
                    allSectors.map((sector, i) => {
                      return (
                        <div className="complete_step4_sector">
                          <input
                            type="checkbox"
                            id={"sector_check" + sector.id}
                            name={sector.name}
                            className="sector_check"
                            onChange={(e) => handleEvent(e, sector)}
                          />
                          <div className="complete_step4_sector_info complete_step4_sector_info_checkbox">
                            <label
                              className="complete_step4_sector_name"
                              for={"sector_check" + sector.id}
                            >
                              {" "}
                              {lang == "en" ? sector.name : sector.name_ar}
                            </label>
                            {sector.description && (
                              <div
                                className="complete_step4_sector_details"
                                onClick={() => showPop(i)}
                              >
                                {t("CompleteProf.complete_your_profile_what")}
                              </div>
                            )}
                          </div>{" "}
                          {openPopUp && (
                            <Popup
                              setOpen={setOpenPopUp}
                              body={
                                <div className="complete_step4_popup">
                                  <h2 className="complete_step4_popup_header">
                                    {t("CompleteProf.complete_your_profile_38")}
                                  </h2>
                                  <div className="complete_step4_popup_body">
                                    <h3 className="complete_step4_popup_name green_color">
                                      {" "}
                                      {lang == "en" ? name : nameAr}
                                    </h3>
                                    <h4 className="complete_step4_popup_desc">
                                      {" "}
                                      {lang == "en" ? desc : descAr}
                                    </h4>
                                  </div>
                                </div>
                              }
                            />
                          )}
                        </div>
                      );
                    })
                  )}
                  {error && <div className="error_msg">{error}</div>}
                </div>
              </div>

              <div className="complete_step1_footer">
                <div className="divider_line"></div>
                <div className="complete_step_footer_button">
                  <button
                    className={
                      size.width > 768
                        ? "primary_button complete_step_1_next_button"
                        : "primary_button complete_step_2_next_button_resp"
                    }
                    type="button"
                    onClick={() => navigate("/completeProfile/org/step2")}
                  >
                    {size.width > 768 ? (
                      t("CompleteProf.complete_your_profile_btn_5")
                    ) : lang == "en" ? (
                      <AiOutlineArrowLeft size="1.5rem" />
                    ) : (
                      <AiOutlineArrowRight size="1.5rem" />
                    )}
                  </button>

                  <div
                    className={
                      size.width > 768
                        ? "complete_step_right_footer"
                        : "complete_step_right_footer_resp"
                    }
                  >
                    <button
                      className={
                        size.width > 500
                          ? "pink_button complete_step_2_complete_button"
                          : "pink_button complete_step_2_next_button_resp"
                      }
                      onClick={() => setIsNext(false)}
                    >
                      {size.width > 500 ? (
                        t("CompleteProf.complete_your_profile_btn_3")
                      ) : (
                        <BiSave size="1.5rem" />
                      )}
                    </button>
                    <button
                      className={
                        size.width > 768
                          ? "primary_button complete_step_2_next_button"
                          : "primary_button complete_step_2_next_button_resp"
                      }
                      onClick={() => setIsNext(true)}
                    >
                      {size.width > 768 ? (
                        t("CompleteProf.complete_your_profile_btn_4")
                      ) : lang == "en" ? (
                        <AiOutlineArrowRight size="1.5rem" />
                      ) : (
                        <AiOutlineArrowLeft size="1.5rem" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )
        }
        footer={
          <div
            className={
              lang == "en"
                ? "complete_profile_footer_slider_green"
                : " complete_profile_footer_slider_green complete_profile_footer_slider_green_ar"
            }
            style={{ width: "60%" }}
          ></div>
        }
      />
    </div>
  );
}

export default CompleteOrg_Step3;
